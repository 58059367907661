.lesson-branchbuttoncontainer-custom {
  border-top: 1px #cccccc78 solid;
  border-bottom: 6px #cccccc78 double;
  background: #eeeeeecf;
  padding-left: 20px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  p {
    color: #6b6b6b !important;
    font-style: italic;
  }
}
