.kp-video-callout_new {
  margin-bottom: 1.5rem;
  position: relative;
  text-align: center;
  width: 100%;

  a {
    display: inline-block;
    margin: 0 auto;

    &:hover {
      .kp-video-callout_content-frame {
        text-decoration: none !important;
      }
    }
  }

  .kp-video-callout_wrapper {
    align-items: center;
    background-color: #fcfcfc;
    border: 1px solid #ddd;
    border-left: 7px solid #5f99ca;
    border-radius: 5px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    color: #5f99ca;
    display: flex;
    font-size: 24px;
    max-width: 50%;
    // min-height: 100px;
    min-width: 400px;
    width: 100%;

    .kp-video-callout_icon-frame {
      width: 20%;

      .fa {
        font-size: 40px;
      }
    }

    .kp-video-callout_content-frame {
      padding: 5px;
      width: 80%;
    }
  }
}
