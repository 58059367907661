.autoload-comment-box {
  border: none;
  background-color: #dbe1ea96;
  padding-top: 15px;
  &:nth-child(1),
  &:nth-child(2) {
    border-right: 5px solid #ffffff;
  }
  .autoload-title {
    text-decoration: none !important;
    font-size: 1.5rem;
    color: #787878 !important;
    line-height: 1rem;
  }
  .autoload-author-name {
    color: #585858;
  }

  .autoload-content {
    text-align: left;
  }
}

.autoload-text-right {
  text-align: right;
}

.centered-load {
  text-align: center;
}
.category-139 .autoload-comment-box:nth-child(even) {
  background-color: #dbe1eae3;
}
