#page-wrapper {
  #page {
    #page-content {
      div[role="main"] {
        .manual-nav {
          &.standalone {
            a {
              text-transform: capitalize;
              background: #004c9c;
              color: white;
              border-radius: 3px;
              border: none;
              margin: 20px 2px;
              padding: 15px 10px;
              font-size: 22px;
              font-weight: 700;
              box-shadow: 0 3px 0 #ddd;
              text-shadow: none;
              transition: background 350ms ease-in-out;
              &:hover {
                background: #16243d;
                color: white;
                transition: background 350ms ease-in-out;
              }
              &:focus {
                outline: 0;
                color: white;
              }
            }
          }
        }

        .hotspot-image-container {
          margin: 0 auto;
          max-width: 800px;
          overflow: hidden;
          position: relative;

          &.with-bottom-tray {
            overflow: visible;

            .hotspot-container {
              .hotspot {
                border-radius: 0;
                height: 9%;
                width: 18.5%;

                &:after {
                  font-size: 16px;
                  left: 10px;
                  position: absolute;
                  top: 0;
                }

                &[data-hotspot="one"] {
                  left: 74%;
                  top: 54%;
                }

                &[data-hotspot="two"] {
                  left: 74%;
                  top: 68%;
                }

                &[data-hotspot="three"] {
                  left: 74%;
                  top: 81%;
                }

                &.active {
                  box-shadow: 0 0 4px 2px #004c9c;
                }
              }
            }

            .content-container {
              position: relative;

              &.bottom-tray {
                max-height: 0;
                overflow-y: hidden;
                transition: max-height 350ms ease-in-out;

                .content-block {
                  left: 0;
                  position: absolute;
                  opacity: 0;
                  top: 0;
                  transform: none;
                  width: 100%;

                  &.show {
                    animation: none;
                    opacity: 1;
                  }
                }

                &.open {
                  max-height: 1000px;
                  overflow-y: visible;
                  transition: max-height 350ms ease-in-out;
                }
              }
            }
          }

          img {
            max-width: 100%;
            width: 100%;
          }

          .hotspot-container {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 2;

            &.six-piece {
              .hotspot {
                height: 22.5%;
                width: 22.5%;

                &[data-hotspot="one"] {
                  left: 39%;
                  top: 4%;
                }

                &[data-hotspot="two"] {
                  left: 69.5%;
                  top: 21.5%;
                }

                &[data-hotspot="three"] {
                  left: 69.5%;
                  top: 56%;
                }

                &[data-hotspot="four"] {
                  left: 39%;
                  top: 73.2%;
                }

                &[data-hotspot="five"] {
                  left: 8.5%;
                  top: 56%;
                }

                &[data-hotspot="six"] {
                  left: 8.5%;
                  top: 21.5%;
                }
              }
            }

            &.consumer {
              .hotspot {
                border-radius: 10px;
                height: 17%;
                width: 32.6%;

                &:after {
                  top: 5px;
                }

                &[data-hotspot="one"] {
                  left: 63%;
                  top: 4.5%;
                }

                &[data-hotspot="two"] {
                  left: 63%;
                  top: 29.5%;
                }

                &[data-hotspot="three"] {
                  left: 63%;
                  top: 54%;
                }

                &[data-hotspot="four"] {
                  height: 16.5%;
                  left: 63%;
                  top: 79%;
                }
              }
            }

            .hotspot {
              border-radius: 50%;
              cursor: pointer;
              height: 25.5%;
              position: absolute;
              width: 25.5%;

              &.no-round {
                border-radius: 15px;
              }

              &:after {
                content: "\f055";
                font-family: FontAwesome;
                font-size: 20px;
                left: 50%;
                position: absolute;
                top: 10px;
                transform: translateX(-50%);
              }

              &.active {
                box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.75);
              }

              &[data-hotspot="one"] {
                left: 5.5%;
                top: 68%;
              }

              &[data-hotspot="two"] {
                left: 37.5%;
                top: 68%;
              }

              &[data-hotspot="three"] {
                left: 69.5%;
                top: 68%;
              }

              &.three-side {
                color: #ddd;
                height: 26.5%;
                width: 19.5%;

                &[data-hotspot="one"] {
                  left: 43%;
                  top: 8%;
                }

                &[data-hotspot="two"] {
                  left: 50.3%;
                  top: 38%;
                }

                &[data-hotspot="three"] {
                  left: 42.8%;
                  top: 68%;
                }

                &::after {
                  font-size: 32px;
                }
              }
            }
          }

          .content-container {
            background-color: transparentize(#000, 1);
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            z-index: 0;

            &.open {
              z-index: 5;

              .content-block.show {
                animation: enter 0.75s 1 ease-out forwards;

                &.bottom {
                  animation: enterbottom 0.75s 1 ease-out forwards;

                  &.triple {
                    animation: entertriple 0.75s 1 ease-out forwards;
                  }
                }
              }
            }

            .content-block {
              background: #16243d;
              border-radius: 5px;
              color: #fff;
              font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
              left: 50%;
              position: absolute;
              transform: translateX(-50%) translateY(-50%);
              top: -50%;
              transition: top 450ms ease-in-out;
              width: 85%;

              &.hide {
                animation: exit 1s 1 ease-out forwards;
              }

              .content-frame {
                padding: 3rem;
                position: relative;

                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                p,
                ul {
                  background: none;
                  margin-top: 0;
                  padding: 0;

                  &:before,
                  &:after {
                    content: none;
                  }
                }

                p {
                  font-size: calc(16px + 6 * ((100vw - 320px) / 680));

                  @media screen and (min-width: 1000px) {
                    font-size: 18px;
                  }

                  &:last-of-type {
                    margin-bottom: 0;
                  }
                }

                .closer {
                  cursor: pointer;
                  position: absolute;
                  right: 8px;
                  top: 7px;

                  .fa {
                    color: #fff;
                    font-size: 18px;
                  }
                }
              }
            }
          }

          &.html {
            overflow: visible;

            .hotspot-container {
              .hotspot {
                border-radius: 10px;
                box-shadow: 0 0 0px 0 rgba(35, 29, 74, 0);
                height: 46%;
                transition: box-shadow 350ms ease-in-out;
                width: 31%;

                &:after {
                  left: 50%;
                  position: absolute;
                  top: 0;
                }

                &:hover {
                  box-shadow: 0 0 7px 0 rgba(35, 29, 74, 1);
                  transition: box-shadow 350ms ease-in-out;
                }

                &[data-hotspot="one"] {
                  left: 1.2%;
                  top: 2%;
                }

                &[data-hotspot="two"] {
                  left: 34.5%;
                  top: 2%;
                }

                &[data-hotspot="three"] {
                  left: 67.75%;
                  top: 2%;
                }

                &[data-hotspot="four"] {
                  left: 1.2%;
                  top: 52%;
                }

                &[data-hotspot="five"] {
                  left: 34.5%;
                  top: 52%;
                }

                &[data-hotspot="six"] {
                  left: 67.75%;
                  top: 52%;
                }
              }
            }

            .chart-container {
              display: flex;
              flex-wrap: wrap;
              position: relative;

              .chart-block {
                align-items: center;
                background-color: #004c9c;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                padding: 2rem;
                margin: 1rem;
                min-height: 200px;
                width: calc(33.33% - 2rem);

                h4 {
                  color: #fff;
                  font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
                  font-weight: 700;
                  text-align: center;
                }
              }
            }

            .content-container {
              position: relative;

              .content-block {
                transform: translateX(-50%) translateY(0%) scale(0);
                transition: none;
                top: 0;
                width: 100%;

                .content-frame {
                  h3 {
                    font-weight: 700;
                  }
                }

                &.show {
                  animation: none;
                  left: 50%;
                  position: relative;
                  top: 0;
                  transform: translateX(-50%) translateY(0%) scale(1);
                  transition: none;

                  &.bottom.triple {
                    animation: none;
                  }
                }
              }
            }
          }
        }

        .content-container.bottom-tray {
          background-color: transparentize(#000, 1);
          height: 100%;
          margin: 0 auto;
          max-height: 0;
          max-width: 800px;
          position: relative;
          overflow-y: hidden;
          transition: max-height 350ms ease-in-out;
          width: 100%;
          z-index: 0;

          &.open {
            max-height: 1000px;
            overflow-y: visible;
            transition: max-height 350ms ease-in-out;
          }

          .content-block {
            background: #16243d;
            border-radius: 5px;
            color: #fff;
            font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
            left: 0;
            position: absolute;
            opacity: 0;
            top: 0;
            transform: none;
            width: 100%;

            &.show {
              animation: none;
              opacity: 1;
              position: relative;
              z-index: 10;
            }

            &.hide {
              animation: exit 1s 1 ease-out forwards;
            }

            .content-frame {
              padding: 3rem;
              position: relative;

              h1,
              h2,
              h3,
              h4,
              h5,
              h6,
              p,
              ul {
                background: none;
                margin-top: 0;
                padding: 0;

                &:before,
                &:after {
                  content: none;
                }
              }

              p {
                font-size: calc(16px + 6 * ((100vw - 320px) / 680));

                @media screen and (min-width: 1000px) {
                  font-size: 18px;
                }

                &:last-of-type {
                  margin-bottom: 0;
                }

                &.callout-link {
                  background: #dee7bc;
                  border-radius: 15px;
                  transition: background 0.5s;
                  height: 78px;

                  &:hover {
                    background: #92af21;
                    cursor: pointer;

                    span {
                      color: #fff;
                    }
                  }

                  span {
                    font-size: 15px;
                  }
                }

                &.callout-transcript {
                  a {
                    font-size: 15px;
                  }
                }
              }

              .closer {
                cursor: pointer;
                position: absolute;
                right: 8px;
                top: 7px;

                .fa {
                  color: #fff;
                  font-size: 18px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes enter {
  0% {
    top: -50%;
  }

  20% {
    top: -25%;
  }

  40% {
    top: 0%;
  }

  60% {
    top: 25%;
  }

  80% {
    top: 53%;
  }

  100% {
    top: 60%;
  }
}

@keyframes enterbottom {
  0% {
    top: -300px;
  }

  50% {
    top: 0px;
  }

  100% {
    top: 20%;
  }
}

@keyframes exit {
  0% {
    top: 50%;
  }

  20% {
    top: 25%;
  }

  40% {
    top: 0%;
  }

  60% {
    top: -50%;
  }

  80% {
    top: -50%;
  }

  100% {
    top: -50%;
  }
}

@keyframes entertriple {
  0% {
    top: -50%;
  }

  50% {
    top: 0%;
  }

  100% {
    top: 25%;
  }
}
