#page-wrapper {
  #page {
    #page-content {
      div[role="main"] {
        button {
          &.vjs-button {
            font-size: inherit;
            margin: inherit;
          }

          &.vjs-big-play-button {
            left: 50%;
            font-size: 3em;
            margin: inherit;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
          }
        }
      }
    }
  }
}
