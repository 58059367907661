.audio-player {
  .audio-content {
    .audio-content-img-container {
      position: relative;

      .audio-content-profile-img {
        position: absolute;
        top: 10%;
        left: 50%;
        border-radius: 50%;
        border: 1px solid #4b4e51;
        background-color: #4b4e51;

        img {
          margin-top: 5px;
        }
      }
    }
    .audio-content-text-container {
      .label {
        font-size: 26px;
        background-color: transparent;
        display: block;
        padding: 0.1em 0;
        text-align: left;
        border-radius: unset;
      }
    }
  }
}
