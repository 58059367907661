#prev-activity-link,
#next-activity-link {
  background: #004c9c;
  border-radius: 3px;
  box-shadow: 0 3px 0 #ddd;
  color: #fff;
  display: block;
  font-size: 22px;
  font-weight: 700;
  padding: 5px 20px;

  &:hover {
    background: darken(#004c9c, 10%);
    text-decoration: none;
  }
}
