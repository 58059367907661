.kp-interactive {
  &#learning-def-quiz {
    ol.learning-choices {
      li {
        list-style-type: none !important;
        background: $exeter-blue;
        color: #fff;
        margin: 1em 1em 1em -2em;
        padding: 1em;
        &:hover,
        &:focus {
          cursor: pointer;
          background: $exeter-sr-green;
        }
        &.selected {
          background: $exeter-sr-green;
          color: #000;
          font-weight: bold;
        }
        &.not-selected {
          background: $exeter-bus-grey;
        }
        &.selected,
        &.not-selected {
          pointer-events: none;
        }
      }
    }
    .learning-definitions {
      display: none;
      margin: 1em;
      background: $exeter-kp-light-grey;
      padding: 1em;
      border-radius: 20px;
      > p:first-child {
        text-align: center;
        color: $exeter-navy;
        font-weight: bold;
      }
      .learn-external,
      .learn-interal {
        display: none;
        margin: 0 1em;
      }
    }
  }
}
