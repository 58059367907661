.generalbox {
  ul.tick {
    list-style: none;
    li:before {
      content: "✓";
      color: green;
      margin-right: 5px;
    }
  }
}
