.intro-borderL img {
  border-radius: 50%;
  width: 100%;
  max-width: 250px;
  height: 100%;
  max-height: 250px;
}

.intro-content {
  text-align: right;
  margin-bottom: 20px;
}

.intro-borderL {
  border-left: 1px solid #bdcad8;
}

.intro-info {
  text-align: left;
}

@media only screen and (max-width: 600px) {
  .intro-borderL {
    border-left: 0px;
  }
}
