#page-wrapper {
  #page {
    #page-content {
      div[role="main"] {
        .box {
          .no-overflow {
            p.end-of-week {
              font-style: italic;
              color: #868e96 !important;
              i.fa {
                color: #004c9c;
              }
            }
          }
        }
      }
    }
  }
}
