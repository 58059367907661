#page-content {
  div[role="main"] {
    .cr-brand-eval,
    .cr-enhance-equity {
      margin: 3rem 2rem 0 2rem;
      position: relative;
      z-index: 1;
      .row {
        overflow: visible !important;
      }
      .cbox {
        border: 2px solid #000;
        height: 80px;
        position: relative;
        width: 80%;
        font-weight: bold;
        border-radius: 10px;
        &:not(.box4):before {
          content: "";
          position: absolute;
          background: #d3d3d3;
          height: 125px;
          width: 5px;
          z-index: -1;
          top: -75px;
        }
        &:hover,
        &.active {
          cursor: pointer;
          border-style: dashed;
        }
        &.box1 {
          background: #b0d993;
          &:before {
            left: 100%;
            transform: rotate(45deg);
          }
        }
        &.box1:hover,
        &.box1.active {
          background: #d7ecc9;
        }

        &.box2 {
          background: #f3c7ab;
          &:before {
            left: 50%;
          }
        }
        &.box2:hover &.box2.active {
          background: #f9e3d5;
        }
        &.box3 {
          background: #c4dff4;
          &:before {
            left: 0;
            transform: rotate(-45deg);
          }
        }
        &.box3:hover,
        &.box3.active {
          background: #e1eff9;
        }
        &.box4 {
          left: 50%;
          height: 75px;
          width: 60%;
          margin-left: -30%;
          margin-bottom: 50px;
          background: #f2da93;
          &:hover,
          &.active {
            background: #f2da93;
            border-style: solid;
            cursor: default;
          }
          p {
            margin-top: 20px;
          }
        }
        p {
          text-align: center;
          margin-top: 20px;
        }
      }

      .cr-explain {
        width: 100%;
        border-top: 1px dotted #afafaf;
        margin-top: 2rem;
        padding-top: 15px;
        border-radius: 0px;
        .statement {
          padding: 10px 15px;
          border-radius: 10px;
          border: 2px solid #000;
          width: 100%;
          margin-top: 20px;
          h3 {
            font-family: sans-serif;
            background: transparent !important;
            color: unset !important;
            padding: 5px 0 0 !important;
            &:after,
            &:before {
              content: none !important;
            }
          }
        }
        &.quality .statement,
        &.speak .statement {
          background: #b0d993;
        }
        &.salience .statement,
        &.message .statement {
          background: #f3c7ab;
        }
        &.equity .statement,
        &.leverage .statement {
          background: #c4dff4;
        }
        &.evaluating .statement,
        &.enhance .statement {
          background: #f2da93;
        }
      }

      @media all and (max-width: 900px) {
        .cbox {
          position: relative;
          min-width: 100px;
          width: 100%;
        }
      }

      @media all and (max-width: 768px) {
        .cbox {
          height: 75px;
        }
      }
    }
  }
}
