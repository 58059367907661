// intended for 1220px max. wrap if the container gets too big

.swiper-container {
  font: normal 400 1em/1.44 "Open Sans", sans-serif;
  color: #05458a;
  letter-spacing: 0.05em;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.35);
  height: 65vh;
  @media screen and (max-width: 1120px) {
    font-size: 1.42vw;
  }
  @media screen and (max-width: 992px) {
    font-size: 1.72vw;
  }
  @media screen and (max-width: 832px) {
    font-size: 1.88vw;
  }
  @media screen and (max-width: 667px) {
    font-size: 2.08vw;
  }
  @media screen and (max-device-width: 667px) and (orientation: portrait) {
    font-size: 2.72vw;
  }
  &::after {
    content: url("../img/svg/md.svg");
    width: 20px;
    display: block;
    z-index: 100;
    position: absolute;
    bottom: 30px;
    right: 50%;
    transform: translateX(-50%);
    opacity: 0.8;
    @media screen and (max-device-width: 667px) and (orientation: portrait) {
      content: "";
    }
  }
  .swiper-wrapper {
    height: inherit;
    .swiper-slide {
      height: inherit;
      z-index: 50;
      .swiper-body {
        background: rgba(255, 255, 255, 0.95);
        padding: 16px 32px;
        position: relative;
        left: 15%;
        width: 55%;
        z-index: 20;
        @media screen and (max-width: 1120px) {
          width: 65.5%;
        }
      }
      .swiper-header {
        color: white;
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.65);
        background: rgba(6, 123, 249, 0.85);
        padding: 16px 32px;
        font: normal 200 2.8vmin/1.2 "Open Sans", sans-serif;
        position: relative;
        margin: 5% 0 2.5% 0;
        left: 15%;
        width: 85%;
        z-index: 30;
        letter-spacing: 2px;
        white-space: normal;
        @media screen and (max-device-width: 667px) and (orientation: portrait) {
          font-size: 3.52vw;
        }
      }
      &.first::before,
      &.second::before,
      &.third::before {
        content: " ";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.75;
        z-index: 1;
        box-shadow: inset 0 0 120px 20px rgba(47, 60, 71, 0.7);
      }
      &.first.swiper-slide-active::before,
      &.second.swiper-slide-active::before,
      &.third.swiper-slide-active::before {
        animation: fadeInUp 600ms ease-out;
      }
      &.first::before {
        background: center/cover no-repeat url("../img/range-sliders/s1.jpg");
      }
      &.second::before {
        background: center/cover no-repeat url("../img/range-sliders/s2.jpg");
      }
      &.third::before {
        background: center/cover no-repeat url("../img/range-sliders/s3.jpg");
      }
    }
    @-webkit-keyframes fadeInUp {
      from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
      }
      to {
        opacity: 0.75;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
    }
    @keyframes fadeInUp {
      from {
        opacity: 0;
        -webkit-transform: translate3d(0, 100%, 0);
        transform: translate3d(0, 100%, 0);
      }
      to {
        opacity: 0.75;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
    }
    @keyframes zoomIn {
      from {
        opacity: 0.25;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
      }
      50% {
        opacity: 0.85;
      }
    }
  }
  .parallax-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 130%;
    height: inherit;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    background-image: url("../img/range-sliders/s3.jpg");
    opacity: 0.65;
  }
  .hint-text {
    position: absolute;
    bottom: 14px;
    width: 100%;
    left: 50%;
    transform: translate(-50%);
    color: white;
    font: italic 200 15px/1 "Open Sans", sans-serif;
    text-align: center;
    z-index: 90;
    text-shadow: 0 0 2px black;
    @media screen and (max-device-width: 667px) and (orientation: portrait) {
      display: none;
    }
  }
  .swiper-pagination {
    margin-right: 2.5%;
    &::before,
    &::after {
      content: "";
      margin: auto;
      height: 18px;
      width: 18px;
      opacity: 0.5;
      display: block;
      background: center/contain no-repeat
        url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
      transform: rotate(90deg);
    }
    &::after {
      transform: rotate(-90deg);
    }
    .swiper-bullet {
      width: 24px;
      height: 24px;
      background: #067af9;
      margin: 12px auto;
      display: block;
      color: rgba(6, 123, 249, 0.85);
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
      cursor: pointer;
      &.active-bullet {
        border-top: 4px solid #067af9;
        width: 32px;
        height: 32px;
        background: white;
        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
        animation: zoomIn 600ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
      }
    }
    .bullet-shadow {
      box-shadow: inset 0 0 6px 0 rgba(77, 5, 5, 0.25);
      background: none;
      border-radius: 50%;
    }
  }
}
