.like-and-res-title {
  color: #004c9c;
}

.like-and-res-container {
  border: none;
}

.like-and-res-btn-container {
  text-align: right;
  margin-top: 10px;
}

.like-and-res-startpage {
  display: none;
}

.like-and-res-startpage p {
  color: #808080;
}

/*========================*/

.like-and-res-commentpage {
  display: none;
}

.like-and-res-comment-outer-container {
  border: none;
}

.like-and-res-comment-container {
  max-height: 70%;
  overflow-y: scroll;
  padding: 20px;
}

.like-and-res-comment-ele {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #d3d3d3;
}

.like-and-res-comment-add-container {
  height: 0;
  overflow: hidden;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.like-and-res-comment-add-container-dropped {
  height: 300px;
}

.like-and-res-comment-content {
  width: 100%;
  resize: none;
  padding: 5px;
  height: 130px;
}

.like-and-res-comment-input-none {
  color: grey;
}

.like-and-res-comment-content {
  margin-top: 5px;
}

/*===========================*/

.like-and-res-comment-name {
  color: #004c9c;
  font-weight: bold;
}

.like-and-res-comment-border-bottom {
  border-bottom: 1px solid #004c9c;
}

.like-and-res-msg-container,
.like-and-res-like-container {
  display: inline-block;
  border: 1px solid #5f99ca;
  border-radius: 5px;
  cursor: pointer;
  padding: 3px 10px;
}

.like-and-res-msg-container:hover i,
.like-and-res-msg-container-selected i {
  color: green;
}

.like-and-res-like-container:hover i,
.like-and-res-like-container-selected i {
  color: blue;
}

.like-and-res-commentoncomment-container {
  margin-top: 10px;
  border-left: 10px solid #8080808a;
  padding-left: 15px;
}

.like-and-res-commentoncomment {
  width: 100%;
  resize: none;
  padding: 5px;
}

/*==============================*/

.commentOnComment-comment-container {
  margin-top: 15px;
}

.commentOnComment-comment {
  border-left: 10px solid #f4f4c0;
  padding-left: 15px;
  margin-bottom: 10px;
}
