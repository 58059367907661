// Exeter Colors
$exeter-bus-grey: #4b4e51;
$exeter-bus-light-blue: #6189b8;
$exeter-bus-light-green: #93ab9e;
$exeter-bus-purple: #311e69;
$exeter-blue: #004c9c;
$exeter-sky-blue: #5f99ca;
$exeter-navy: #16243d;
$exeter-green: #375251;
$exeter-sr-green: #92af21; // Colors generated from Exeter palette
$exeter-kp-grey: #dbd9d6;
$exeter-kp-light-grey: #f9f9f9;
$exeter-kp-red: #af1926; // mixin
$exeter-btn-green: #009c54;

.category-139 {
@import "kp-styles/_assessment-carousel.scss";
@import "kp-styles/_autoloadComment.scss";
@import "kp-styles/_blockquote.scss";
@import "kp-styles/_book-navigation.scss";
@import "kp-styles/_book-suggestion.scss";
@import "kp-styles/_bs-tooltip.scss";
@import "kp-styles/_definition.scss";
@import "kp-styles/_div-style.scss";
@import "kp-styles/_EFPM006.scss";
@import "kp-styles/_ending-activity.scss";
@import "kp-styles/_flipclock.scss";
@import "kp-styles/_footer.scss";
@import "kp-styles/_gizmo.scss";
@import "kp-styles/_hero-images.scss";
@import "kp-styles/_hotspot-clickreveal.scss";
@import "kp-styles/_instructions.scss";
@import "kp-styles/_kpGrid.scss";
@import "kp-styles/_learning-definition-quiz.scss";
@import "kp-styles/_lesson-page.scss";
@import "kp-styles/_like-and-respond.scss";
@import "kp-styles/_manual-nav.scss";
@import "kp-styles/_new-nav.scss";
@import "kp-styles/_overview-tabs.scss";
@import "kp-styles/_owl-carousel.scss";
@import "kp-styles/_paralax-sections.scss";
@import "kp-styles/_podcast-snippet.scss";
@import "kp-styles/_quickfix-needs-update.scss";
@import "kp-styles/_range-sliders.scss";
@import "kp-styles/_reflective-learning.scss";
@import "kp-styles/_snippet-update.scss";
@import "kp-styles/_snippets.scss";
@import "kp-styles/_spacers.scss";
@import "kp-styles/_special-table.scss";
@import "kp-styles/_styles.scss";
@import "kp-styles/_support_block.scss";
@import "kp-styles/_swiper-main.scss";
@import "kp-styles/_swiper-slider.scss";
@import "kp-styles/_test-headings.scss";
@import "kp-styles/_tick-unordered-list.scss";
@import "kp-styles/_timeline_dragdrop.scss";
@import "kp-styles/_turnitin.scss";
@import "kp-styles/_tutorIntro.scss";
@import "kp-styles/_twentytwenty.scss";
@import "kp-styles/_uoe-slider.scss";
@import "kp-styles/_video-player-styles.scss";
@import "kp-styles/_wordcloudnew.scss";

}

@mixin target-clear {
  &:after,
  &:before {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin clean-header {
  &:after,
  &:before {
    display: none;
  }
}

.clean-header {
  &:after,
  &:before {
    display: none;
  }
} // for courses with grids (:not to exclude inductions)
body.pagelayout-course:not(.course-6982):not(.course-7092):not(.course-7149):not(.course-7093):not(.course-7220):not(.course-7277):not(.course-7321):not(.course-6857) {
  &.category-139:not(.editing) {
    .course-content .current::before {
      border-left: none !important;
    }
    .topics {
      //audio player for topics page
      .mediaplugin.mediaplugin_videojs {
        margin: 0;
        & > div {
          max-width: unset !important;
          max-width: none !important;
          width: 100% !important;
        }
      }
      .vjs-play-control.vjs-control.vjs-button {
        margin: 0 !important;
        font-size: 1em !important;
        border-radius: 0 !important;
        box-shadow: none !important;
      }
      .audio-player {
        box-shadow: #ddd;
        margin-bottom: 40px;
      }
      .audio-content {
        background: $exeter-bus-grey url("../img/globe.jpg") no-repeat right -40px;
        color: white;
        padding: 20px;
        h3 {
          color: white !important;
          margin-top: 0 !important;
          margin-bottom: 5px !important;
        }
        & > .label {
          font-size: 26px;
          background-color: transparent;
          display: block;
          padding: 0.1em 0;
          text-align: left;
          border-radius: unset;
          border-radius: none;
        }
      }
      li.section:not(#section-0) {
        background-size: cover !important;
        display: inline-block;
        width: 32%;
        margin-right: 1%;
        padding: 0 !important;
        border: none !important;
        text-align: center;
        vertical-align: top;
        @media all and (max-width: 1400px) {
          width: 49%;
          margin-right: 1%;
        }
        @media all and (max-width: 600px) {
          width: 100%;
        }
        h3 a {
          display: block;
          background: $exeter-blue;
          padding: 15px;
          // height: 252px;
          // @media only screen and (min-width: 601px) and (max-width: 957px) {
          //   height: 272px;
          // }
          &:before {
            content: "";
            display: block;
            // height: 190px;
            margin: -15px -15px 15px;
            background-size: cover !important;
            @media all and (min-width: 1680px) {
              background-position: 0 -30px !important;
            }
          }
          &:hover {
            background: #1185ff;
          }
        }
      }
      h3.section-title {
        opacity: 0;
        cursor: pointer;
      }
      h3.sectionname {
        a {
          height: 252px;
        }
      }
      #completionprogressid {
        margin-right: 25px;
      }
      .section-title {
        font-size: 15px;
        background: rgba($exeter-blue, 1);
        margin: 0 0 15px;
        height: 252px;
        position: relative;
        background-color: #6d7680;
        background-size: 100% 100% !important;
        background-repeat: no-repeat !important;
        a {
          text-decoration: none;
          display: block;
          color: #fff;
          background-color: #004c9c;
          height: 60px;
          position: absolute;
          bottom: 0px;
          width: 100%;
        }
      }
      .section-summary-activities,
      .summarytext {
        display: none;
      }
    }
  }
  &.category-139 {
    &.editing {
      .summary {
        // margin-top: -37px !important;
      }
    }
  }
}

// induction with tiles
body.pagelayout-course {
  &.course-6858:not(.editing),
  &.course-7523:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/induction/tiles/week1.jpg");
          }
        }
        &#section-2 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/induction/tiles/week2.jpg");
          }
        }
        &#section-3 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/induction/tiles/week3.jpg");
          }
        }
        &#section-4 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/induction/tiles/week4.jpg");
          }
        }
        &#section-5 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/induction/tiles/week5.jpg");
          }
        }
        &#section-6 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/induction/tiles/week6.jpg");
          }
        }
      }
    }
  }
}

body.pagelayout-course {
  &.course-6736:not(.editing),
  &.course-7221:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm116/pib_week0.jpg");
          }
        }
        &#section-2 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm116/pib_week1.jpg");
          }
        }
        &#section-3 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm116/pib_week2.jpg");
          }
        }
        &#section-4 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm116/pib_week3.jpg");
          }
        }
        &#section-5 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm116/pib_week4.jpg");
          }
        }
        &#section-6 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm116/pib_week5.jpg");
          }
        }
        &#section-7 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm116/pib_week6.jpg");
          }
        }
        &#section-8 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm116/pib_week7.jpg");
          }
        }
      }
    }
  }
}

body.pagelayout-course {
  // &.course-6736:not(.editing),
  &.course-7172:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url();
          }
        }
        &#section-2 {
          h3 a:before {
            background: url();
          }
        }
        &#section-3 {
          h3 a:before {
            background: url();
          }
        }
        &#section-4 {
          h3 a:before {
            background: url();
          }
        }
        &#section-5 {
          h3 a:before {
            background: url();
          }
        }
        &#section-6 {
          h3 a:before {
            background: url();
          }
        }
        &#section-7 {
          h3 a:before {
            background: url();
          }
        }
        &#section-8 {
          h3 a:before {
            background: url();
          }
        }
        &#section-9 {
          h3 a:before {
            background: url();
          }
        }
        &#section-10 {
          h3 a:before {
            background: url();
          }
        }
        &#section-11 {
          h3 a:before {
            background: url();
          }
        }
        &#section-12 {
          h3 a:before {
            background: url();
          }
        }
        &#section-13 {
          h3 a:before {
            background: url();
          }
        }
        &#section-14 {
          h3 a:before {
            background: url();
          }
        }
        &#section-15 {
          h3 a:before {
            background: url();
          }
        }
      }
    }
  }
}

body.pagelayout-course {
  &.course-6738:not(.editing),
  &.course-6980:not(.editing),
  &.course-7222:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm148/week0.jpg");
          }
        }
        &#section-2 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm148/week1.jpg");
          }
        }
        &#section-3 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm148/week2.jpg");
          }
        }
        &#section-4 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm148/week3.jpg");
          }
        }
        &#section-5 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm148/week4.jpg");
          }
        }
        &#section-6 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm148/week5.jpg");
          }
        }
        &#section-7 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm148/week6.jpg");
          }
        }
        &#section-8 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm148/week7.jpg");
          }
        }
      }
    }
  }
}

body.pagelayout-course {
  &.course-7006:not(.editing),
  &.course-7007:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/adr/section1.jpg");
          }
        }
        &#section-2 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/adr/section2.jpg");
          }
        }
        &#section-3 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/adr/section3.jpg");
          }
        }
        &#section-4 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/adr/section4.jpg");
          }
        }
        &#section-5 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/adr/section5.jpg");
          }
        }
        &#section-6 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/adr/section6.jpg");
          }
        }
      }
    }
  }
}

body.pagelayout-course {
  &.course-7054:not(.editing),
  &.course-7087:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm172/bemm172-welcome.jpg");
          }
        }
        &#section-2 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm172/bemm172-unit1.jpg");
          }
        }
        &#section-3 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm172/bemm172-unit2.jpg");
          }
        }
        &#section-4 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm172/bemm172-unit3.jpg");
          }
        }
        &#section-5 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm172/bemm172-unit4.jpg");
          }
        }
        &#section-6 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm172/bemm172-unit5.jpg");
          }
        }
        &#section-7 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm172/bemm172-unit6.jpg");
          }
        }
        &#section-8 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm172/bemm172-unit7.jpg");
          }
        }
      }
    }
  }
}

body.pagelayout-course {
  &.course-7055:not(.editing),
  &.course-7089:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm120/bemm120-welcome.jpg");
          }
        }
        &#section-2 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm120/bemm120-unit1.jpg");
          }
        }
        &#section-3 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm120/bemm120-unit2.jpg");
          }
        }
        &#section-4 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm120/bemm120-unit3.jpg");
          }
        }
        &#section-5 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm120/bemm120-unit4.jpg");
          }
        }
        &#section-6 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm120/bemm120-unit5.jpg");
          }
        }
        &#section-7 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm120/bemm120-unit6.jpg");
          }
        }
        &#section-8 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm120/bemm120-unit7.jpg");
          }
        }
      }
    }
  }
}

body.pagelayout-course {
  &.course-7108:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-welcome.jpg");
          }
        }
        &#section-2 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit1.jpg");
          }
        }
        &#section-3 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit2.jpg");
          }
        }
        &#section-4 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit3.jpg");
          }
        }
        &#section-5 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit4.jpg");
          }
        }
        &#section-6 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit5.jpg");
          }
        }
        &#section-7 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit6.jpg");
          }
        }
        &#section-8 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit7.jpg");
          }
        }
        &#section-9 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit8.jpg");
          }
        }
        &#section-10 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit9.jpg");
          }
        }
        &#section-11 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit10.jpg");
          }
        }
        &#section-12 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit11.jpg");
          }
        }
        &#section-13 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit12.jpg");
          }
        }
        &#section-14 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit13.jpg");
          }
        }
        &#section-15 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit14.jpg");
          }
        }
      }
    }
  }
}
body.pagelayout-course {
  &.course-7076:not(.editing),
  &.course-7184:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm166/bemm166-unit1.jpg");
          }
        }
        &#section-2 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm166/bemm166-unit2.jpg");
          }
        }
        &#section-3 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm166/bemm166-unit3.jpg");
          }
        }
        &#section-4 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm166/bemm166-unit4.jpg");
          }
        }
        &#section-5 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm166/bemm166-unit5.jpg");
          }
        }
        &#section-6 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm166/bemm166-unit6.jpg");
          }
        }
        &#section-7 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm166/bemm166-unit7.jpg");
          }
        }
        &#section-8 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm166/bemm166-unit8.jpg");
          }
        }
      }
    }
  }
}
body.pagelayout-course {
  &.course-7201:not(.editing),
  &.course-7322:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm177/bemm177-unit0.jpeg");
          }
        }
        &#section-2 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm177/bemm177-unit1.jpeg");
          }
        }
        &#section-3 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm177/bemm177-unit2.jpeg");
          }
        }
        &#section-4 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm177/bemm177-unit3.jpeg");
          }
        }
        &#section-5 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm177/bemm177-unit4.jpeg");
          }
        }
        &#section-6 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm177/bemm177-unit5.jpeg");
          }
        }
        &#section-7 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm177/bemm177-unit6.jpeg");
          }
        }
        &#section-8 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm177/bemm177-unit7.jpeg");
          }
        }
      }
    }
  }
}

body.pagelayout-course {
  &.course-7200:not(.editing),
  &.course-7276:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm115/unit1.jpg");
          }
        }
        &#section-2 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm115/unit2.jpg");
          }
        }
        &#section-3 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm115/unit3.jpg");
          }
        }
        &#section-4 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm115/unit4.jpg");
          }
        }
        &#section-5 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm115/unit5.jpg");
          }
        }
        &#section-6 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm115/group.jpg");
          }
        }
        &#section-7 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm115/unit6.jpg");
          }
        }
        &#section-8 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm115/unit7.jpg");
          }
        }
      }
    }
  }
}

body.pagelayout-course {
  &.course-7310:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-welcome.jpg");
          }
        }
        &#section-2 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit1.jpg");
          }
        }
        &#section-3 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit2.jpg");
          }
        }
        &#section-4 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit3.jpg");
          }
        }
        &#section-5 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit4.jpg");
          }
        }
        &#section-6 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit5.jpg");
          }
        }
        &#section-7 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit6.jpg");
          }
        }
        &#section-8 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit7.jpg");
          }
        }
        &#section-9 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit8.jpg");
          }
        }
        &#section-10 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit9.jpg");
          }
        }
        &#section-11 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit10.jpg");
          }
        }
        &#section-12 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit11.jpg");
          }
        }
        &#section-13 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit12.jpg");
          }
        }
        &#section-14 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit13.jpg");
          }
        }
        &#section-15 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-unit14.jpg");
          }
        }
      }
    }
  }
}
.course-content {
  .single-section {
    margin-top: 0em !important;
  }
}
// for courses with unit heroes
body.pagelayout-course {
  background-color: #eee;
  &.category-139 {
    .course-content {
      ul.section {
        margin: 1rem 25px;
      }
      #section-0 {
        .content {
          h3.sectionname {
            z-index: 3;
            top: 2.3rem;
            margin-left: 0.35rem !important;
            margin-bottom: 0 !important;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 10px;
            color: white;
            width: 39%;
            position: absolute;
            display: inline-block;
            background: $exeter-bus-grey;
            text-align: left;
            @media screen and (max-width: 1366px) and (orientation: landscape) {
              top: unset;
              top: none;
              margin-top: 0;
              width: 29%;
            }
            @media all and (max-width: 768px) {
              top: 0;
              position: relative;
              width: 100%;
              line-height: 200%;
              font-size: 100%;
              text-align: center;
              margin: 0;
              @include clean-header;
            }
            &:before {
              content: " ";
              background-repeat: no-repeat;
              background-position-x: 80%;
              position: absolute;
              background: linear-gradient(
                to bottom right,
                #4b4e51 calc(50% - 1px),
                #4b4e51 calc(50% - 1px),
                #4b4e51 49%,
                transparent 50%
              );
              height: 100%;
              left: 100%;
              top: 0;
              width: 30%;
              z-index: 0;
            }
            a {
              font-family: Georgia, serif;
              color: #fff !important;
            }
            &:after {
              display: none;
            }
          }
          .summary {
            position: relative;
            left: -1em;
            .unit-hero {
              margin: 0 2rem 0 0;
              max-width: 100%;
              max-height: 400px;
              overflow: hidden;
              border-bottom: 4px solid $exeter-bus-grey;
              @media all and (max-width: 768px) {
                margin: 0;
              }
              img {
                width: 100%;
              }
            }
            @media all and (max-width: 768px) {
              left: 0;
              margin-left: 0;
            }
          }
        }
      }
      .single-section {
        > .topics {
          > li.section {
            width: 100% !important;
            display: initial !important;
            text-align: left !important;
            background-size: unset !important;
            margin-right: 0 !important;
          }
        }
        .section-navigation {
          h3.sectionname {
            z-index: 3;
            margin-top: 0 !important;
            // top: 40px;
            // margin-left: -27px !important;
            &:before {
              background: linear-gradient(
                to bottom right,
                #4b4e51 calc(50% - 1px),
                #4b4e51 calc(50% - 1px),
                49%,
                transparent 50%
              ) !important;
            }
            &:after {
              display: none;
            }
          }
        }
        h3.sectionname.accesshide {
          visibility: hidden;
        }
        .summary {
          position: relative;
          padding-bottom: 1em;
          .atto_image_button_middle {
            margin: 0;
          }
          .unit-hero {
            margin: 0 25px 0 0;
            max-height: 400px;
            overflow: hidden;
            border-bottom: 4px solid $exeter-bus-grey;
            img {
              width: 100%;
              display: block;
              margin: 0;
              max-width: 100%;
            }
          }
          @media all and (max-width: 768px) {
            left: 0;
            margin-left: -2em;
            margin-right: -2em;
          }
        }
        & ul.section {
          & li.activity {
            & .actions {
              top: 6px;
              right: 20px;
              input[type="image"] {
                width: 22px;
                cursor: pointer;
              }
            }
          }
          & li.activity:hover {
            background: #ecf2f9;
          }
          & li.activity:focus {
            background: #ecf2f9;
          }
          & li.activity.label:hover {
            background: #ffffff;
          }
          & li.activity.label:focus {
            background: #ffffff;
          }
        }
      }
      .section.main {
        .summarytext {
          .unit-hero {
            display: none !important;
          }
        }
      }
    }
  }
  &.course-6982,
  &.course-7092,
  &.course-7093,
  &.course-7149,
  &.course-7220,
  &.course-7277,
  &.course-7321,
  &.course-6857 {
    // Fix for Induction margin issue
    .single-section {
      .summary {
        margin-top: -110px !important;
      }
    }
  }
}

body.pagelayout-course {
  &.category-139 {
    div.course-content > ul li.section:only-child {
      display: none;
    }
    .course-content {
      line-height: 2;
      overflow: hidden;
      margin: 0 1em;
      .video-callout,
      .drive-callout {
        position: relative;
        max-width: 50%;
        min-width: 300px;
        left: 50%;
        margin-left: -25%;
        text-align: center;
        box-sizing: border-box !important;
        p.callout-link {
          display: table;
          background: #dee7bc;
          border-radius: 15px;
          -webkit-transition: background 0.5s;
          transition: background 0.5s;
          margin-left: auto;
          margin-right: auto;
          a {
            text-decoration: none !important;
            & > i.fa-video-camera:after {
              opacity: 1;
            }
          }
          &:hover {
            background: #92af21;
            cursor: pointer;
            border-radius: 15px;
            & > * {
              color: white;
              cursor: pointer;
            }
          }
        }
        i.fa-video-camera,
        i.fa-folder-open {
          display: table-cell;
          vertical-align: middle;
          padding: 10px;
          color: white;
          border-top-left-radius: 15px;
          border-bottom-left-radius: 15px;
          background: #92af21;
          width: 20%;
          &:after {
            content: " \f04b";
            font-family: "FontAwesome";
            font-size: 40%;
            color: #92af21;
            position: absolute;
            margin-left: -32px !important;
            margin-top: 12px !important;
            opacity: 0;
            transition: opacity 0.4s;
          }
        }
        i.fa-folder-open:after {
          display: none;
        }
        span {
          display: table-cell;
          position: relative;
          padding: 3%;
          width: 80%;
        }
        a {
          font-weight: bold;
        }
        .callout-note {
          font-style: italic;
          padding: 10px 15px;
          min-width: 270px;
          background: #f9f9f9;
          border-radius: 15px;
          text-align: center;
        }
        .callout-transcript {
          text-align: center;
          font-size: 0.9rem;
          margin-top: -0.5rem;
        }
      }
      .drive-callout p.callout-link {
        i {
          background: $exeter-navy !important;
        }
        background: #e7e9eb !important;
        a {
          color: $exeter-navy;
        }
        &:hover {
          background: $exeter-navy !important;
        }
      }
      .video-container {
        width: 600px;
        max-width: 100%;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        .video-wrapper {
          position: relative;
          padding-bottom: 56.25%;
          /* 16:9 */
          padding-top: 25px;
          height: 0;
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
      .activity {
        h1,
        h4 {
          color: $exeter-bus-grey;
        }
        .piece-container {
          overflow: hidden;
          hr {
            border-top: none !important;
            margin: 0 !important;
            padding: 0 !important;
          }
          & > * {
            border-bottom: 2px dotted #ddd;
            margin: 1rem 0 2rem;
            padding: 2rem 1rem;
          }
          & > *:last-child {
            border-bottom: none;
          }
          .side-by-side {
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -ms-flex-pack: distribute;
            justify-content: space-around;
            margin: 1rem -1rem;
            padding: 1rem 0;
            &.pure-flex {
              .side {
                margin: 1rem 0 !important;
                width: auto !important;
              }
            }
            .side {
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              -webkit-flex-direction: column;
              -ms-flex-direction: column;
              flex-direction: column;
              padding: 1rem;
              width: 50%;
              &.small {
                width: 20% !important;
                & + .side {
                  width: 80% !important;
                  align-items: left !important;
                }
              }
              img {
                max-width: 100%;
                width: 100%;
                & + p {
                  background: #ddd;
                  padding: 1rem;
                  text-align: center;
                  width: 100%;
                }
              }
            }
          }
          .media img {
            max-width: 250px;
            padding: 0 1.5rem 1rem;
          }
        }
      }
      .single-section {
        .audio-player {
          .audio-content {
            a {
              color: #fff;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
        // To force summary text to show if a module has opted for both a grid course homepage and summary text
        .summarytext {
          display: block;
        } // stop section nav links from messing with the headers below
        .mdl-right,
        .mdl-left {
          float: none;
          display: inline-block;
        }
        .mdl-right {
          margin-left: 0 !important;
          padding-left: 1em;
          width: 50%;
        }
        .mdl-left {
          margin-right: 0 !important;
          padding-right: 1em;
          width: 50%;
        } // The Unit Overview link on every Section page
        .section-2,
        .section-3,
        .section-4,
        .section-5,
        .section-6,
        .section-7,
        .section-8 {
          ul.section li.activity.page:first-of-type {
            border: 10px solid rgba(128, 128, 128, 0.15);
            margin-bottom: 20px;
            .mod-indent-outer {
              padding: 10px;
              div.activityinstance a span.instancename {
                font-size: larger;
              }
            }
          }
        } // Spaces out the resouces better
        .section .activity .activityinstance,
        .section .activity .activityinstance div {
          margin: 0.25em 0;
        }
        ul.topics li.section {
          padding-top: 0;
          margin-top: -3em;
        }
        .section-navigation {
          margin: 0 25px;
          position: relative;
          h3.sectionname {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 10px;
            color: white;
            width: 60%;
            position: absolute;
            // display: inline-block;
            background: $exeter-bus-grey;
            text-align: left;
            left: 0;
            // top: 0px !important;
            @media all and (max-width: 768px) {
              margin: 0 !important;
              width: calc(100% + 50px);
              left: -25px;
              line-height: 200%;
              font-size: 110%;
              text-align: center;
              @include clean-header;
            }
            &:before {
              content: " ";
              background-repeat: no-repeat;
              background-position-x: 80%;
              position: absolute;
              background: linear-gradient(
                to right top,
                #4b4e51 calc(50% - 1px),
                #4b4e51 calc(50% - 1px),
                #4b4e51 49%,
                transparent 50%
              );
              height: 100%;
              left: 100%;
              top: 0;
              width: 30%;
              z-index: 0;
            }
            &:after {
              content: " ";
              border-bottom: 2px solid $exeter-bus-grey;
              width: 160%;
              height: 2px;
              position: absolute;
              left: 0;
              bottom: 0;
              z-index: 0;
              overflow: hidden !important;
            }
          }
        }
        dl,
        ol,
        a {
          text-decoration: none;
          &:hover {
            text-decoration: none;
          }
        }
        .left,
        .right {
          padding: 0;
          img {
            height: 0;
            display: none;
          }
        }
        h1,
        h2,
        h3,
        h4,
        h5 {
          margin-bottom: 1em !important;
          font-family: Georgia, serif;
        }
        h3 {
          margin-bottom: 0 !important;
        }
        h2,
        h3 {
          margin-bottom: 1rem !important;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          padding: 10px;
          color: white;
          width: 60%;
          position: relative;
          display: inline-block;
          background: $exeter-bus-grey;
          &:before {
            content: " ";
            background-repeat: no-repeat;
            background-position-x: 80%;
            position: absolute;
            background: linear-gradient(
              to right top,
              #4b4e51 calc(50% - 1px),
              #4b4e51 calc(50% - 1px),
              #4b4e51 49%,
              transparent 50%
            );
            height: 100%;
            left: 100%;
            top: 0;
            width: 30%;
            z-index: 0;
          }
          &:after {
            content: " ";
            border-bottom: 2px solid $exeter-bus-grey;
            width: 160%;
            height: 2px;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 0;
            overflow: hidden !important;
          }
        }
        h1,
        h4 {
          color: $exeter-bus-grey;
          margin-top: 1.75rem;
        }
        .activity {
          ul {
            margin-right: 32px;
            margin-left: 32px;
            li {
              list-style-type: none;
              &:before {
                font-family: "FontAwesome";
                content: "\f105 ";
                margin-left: -15px;
                margin-right: 3px;
              }
            }
          }
        }
        .wrap-left {
          float: left;
          margin: 10px 10px 10px 0;
        }
        .wrap-right {
          float: right;
          margin: 10px 0 10px 10px;
        }
        table {
          margin: inherit 0;
          display: block;
          tr {
            &:last-of-type {
              td {
                border-bottom: none;
              }
            }
          }
          td,
          th {
            padding: 12px 15px;
            text-align: left;
            border-bottom: 1px solid #e1e1e1;
          }
        }
        .activities {
          overflow: hidden;
          table {
            border: 1px solid $exeter-green;
            border-radius: 5px;
            box-shadow: inset 0 0 92px 0 rgba(0, 0, 0, 0.1);
            margin: 3rem 0;
            padding: 2rem 4rem;
            width: 100%;
            tr {
              &:first-of-type {
                th {
                  border-bottom: $exeter-green !important;
                }
              }
            }
            img {
              width: 100%;
              max-width: 50px;
              margin: 5px 5px 0 10px;
            }
            h2 {
              text-align: left;
            }
          }
        }
      }
      img.resource-type {
        max-width: 40px;
      }
      .image-content {
        margin: 15px 0 30px;
        background-position: 200px 0;
        background-size: cover;
        @media all and (max-width: 1240px) {
          background: transparent !important;
        }
        a {
          color: #fff !important;
        }
        .title {
          background: $exeter-bus-grey url("../img/globe.jpg") no-repeat right -40px;
          color: white;
          padding: 20px;
          width: 35%;
          @media all and (max-width: 1240px) {
            width: 100%;
          }
        }
        .info {
          font-size: 30px;
          color: white;
          margin-top: 0;
          margin-bottom: 20px;
          padding: 0;
          line-height: initial;
        }
      }
      .jumpmenu {
        margin-top: 5px;
        margin-bottom: 10px;
      }
    }
  }
  &.course-6736,
  &.course-7221 {
    .pull-xs-right {
      clear: both !important;
    }
    .block_rss_client {
      max-width: 100%;
      overflow: hidden;
      padding-right: 10px;
      li {
        border-top: 1px solid rgba($exeter-bus-grey, 0.3);
        &:first-child {
          border-top-width: 0;
        }
        &:nth-of-type(even) {
          background: $exeter-kp-light-grey;
        }
      }
      h3.card-title {
        background: $exeter-blue;
        color: white;
        margin: -1.25rem -1.25rem 0;
        padding: 1.25rem;
        &:before {
          font-family: "FontAwesome";
          content: "\f1ea \0020";
          margin-right: 5px;
        }
      }
      .image {
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .title {
        margin-top: 15px;
        font-weight: bold;
        text-transform: uppercase;
        &:before {
          font-family: "FontAwesome";
          content: "\f09e \0020";
        }
      }
    }
  }
  &.course-6858,
  &.course-7220 {
    .section-summary-activities > .activity-count {
      display: none !important;
    }
    li#section-6 {
      border-bottom: 1px solid #f4f4f4;
    }
  }
}

body.path-mod-quiz {
  &.category-139 {
    #page {
      section#region-main {
        div[role="main"] {
          line-height: 2;
          .info {
            h1,
            h2,
            h3,
            h4,
            h5 {
              margin-bottom: 0.5rem !important;
              font-weight: 500;
              padding: 0;
              font-size: inherit;
              line-height: 1.1;
              color: inherit;
              border: none;
              background: transparent;
              @include clean-header;
            }
          }
          .editor_atto_toolbar {
            display: block;
            background: #f2f2f2;
            min-height: 35px;
            border: 1px solid #bbb;
            width: 100%;
            padding: 0 9px 9px 0;
            .atto_group {
              display: inline-block;
              border: 1px solid #ccc;
              border-bottom: 1px solid #b3b3b3;
              border-radius: 4px;
              margin: 9px 0 0 9px;
              background: #fff;
              button {
                padding: 4px 9px;
                background: none;
                border: 0;
                margin: 0;
                border-radius: 0;
                font-size: initial !important;
                border-right: 1px solid #bbb;
                cursor: pointer;
                -webkit-box-shadow: 0 0 0 transparent;
                box-shadow: 0 3px 0 transparent;
                img.icon {
                  padding: 0;
                  margin: 2px 0;
                  vertical-align: text-bottom;
                  width: auto;
                  height: auto;
                }
                &.highlight,
                &:active {
                  background-image: radial-gradient(ellipse at center, #fff 40%, #dfdfdf 100%);
                  background-color: #dfdfdf;
                }
                &:hover {
                  background-image: radial-gradient(ellipse at center, #fff 60%, #dfdfdf 100%);
                  background-color: #ebebeb;
                }
                &:last-child {
                  border-right: none !important;
                }
              }
            }
          }
        }
      }
      #block-region-side-pre {
        #mod_quiz_navblock {
          .qnbutton {
            height: 30px;
            width: 30px;
            padding-top: 5px;
            &.complete .trafficlight {
              background-color: rgba(128, 128, 128, 0.15);
              margin-top: 0;
              height: 30px;
            }
          }
        }
      }
    }
  }
}

body.pagelayout-incourse {
  background-color: #eee;
  &.category-139 {
    .no-overflow {
      overflow: inherit;
    }

    /////////////////////////////////
    // FIXES FOR POLL STYLING ONLY //
    /////////////////////////////////

    &.path-mod-feedback {
      #page {
        section#region-main {
          div[role="main"] {
            .nav-tabs {
              margin-top: 1rem !important;
              margin-left: 16px !important;
              margin-right: 48px !important;
              .nav-item {
                margin-bottom: 0 !important;
                &:before {
                  content: none !important;
                }
              }
            }
          }
        }
      }
    }

    /////////////////////////////////
    // FIXES FOR WIKI STYLING ONLY //
    /////////////////////////////////

    &.path-mod-ouwiki {
      #page {
        section#region-main {
          div[role="main"] {
            .nav-tabs {
              .nav-item {
                margin-bottom: 0;

                &::before {
                  content: none;
                }
              }
            }
          }
        }
      }
    }
    #page {
      section#region-main {
        div[role="main"] {
          position: relative;
          overflow: hidden;
          margin: 0 auto;
          padding: 0 20px;
          width: 100%;
          max-width: 960px;
          height: 100%;
          text-rendering: optimizeLegibility;
          box-sizing: border-box;
          line-height: 2;

          .branchbuttoncontainer {
            border-top: 1px #cccccc78 solid;
            border-bottom: 6px #cccccc78 solid;
            background: #eeeeeecf;
            padding-left: 20px;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
          }
          .lesson-branchbuttoncontainer-custom {
            button[type="submit"] {
              background: #008547;
            }
          }

          .glossarycategoryheader {
            h3 {
              color: black;
              width: 100%;
              background: none;
              &:before,
              &:after {
                content: none;
              }
            }
          }
          .atto_group {
            button {
              background: #ffffff;
              color: #000000;
              margin: 0;
              padding: 4px 9px;
              font-size: 14px;
              border: 1px solid #ddd;
              > img.icon {
                max-width: 100%;
                vertical-align: middle;
              }
            }
          }
          img {
            max-width: calc(100% - 1em);
          }
          & img.icon.smallicon {
            max-width: 100%;
          }
          .iframe-container {
            height: 0px;
            overflow: hidden;
            padding-top: 0px;
            padding-bottom: 100%;
            position: relative;
            iframe {
              left: 0px;
              top: 0px;
              width: 100%;
              height: 100%;
              position: absolute;
            }
          }
          .mceToolbar {
            table {
              td {
                padding: 0;
              }
            }
          }
          table {
            margin: inherit 0;
            tr {
              &:last-of-type {
                td {
                  border-bottom: none;
                }
              }
            }
            td,
            th {
              padding: 12px 15px;
              text-align: left;
              border-bottom: 1px solid #e1e1e1;
              img {
                max-width: none;
              }
            }
          }
          .box.generalbox.boxaligncenter.p-y-1 {
            .complete-feedback {
              display: inline-block;
              background: #004c9c;
              color: #fff;
              padding: 5px 15px;
              font-size: 18px;
              border-radius: 3px;
              box-shadow: 0 3px 0 #ddd;
              margin-left: 50%;
              transform: translateX(-50%);
              transition: background-color 350ms ease-in-out;
              &:hover {
                background-color: #16243d;
              }
              a {
                color: #fff;
                text-decoration: none;
              }
              a:hover {
                color: #fff;
                text-decoration: underline;
              }
              a:active {
                color: #fff;
                text-decoration: none;
              }
            }
          }
          .activities {
            overflow: hidden;
            table {
              border: 1px solid $exeter-green;
              border-radius: 5px;
              box-shadow: inset 0 0 92px 0 rgba(0, 0, 0, 0.1);
              margin: 3rem 0;
              padding: 2rem 4rem;
              width: 100%;
              tr {
                &:first-of-type {
                  th {
                    border-bottom: $exeter-green !important;
                  }
                }
              }
              img {
                width: 100%;
                max-width: 50px;
                margin: 5px 5px 0 10px;
              }
              h2 {
                text-align: left;
              }
            }
          }
          .row {
            max-width: 100%;
            margin: 0;
            overflow: hidden;
            background: transparent !important;
            /* MISC; related to spacing */
            &:after {
              content: "";
              display: table;
              clear: both;
            }
          }
          &:after {
            content: "";
            display: table;
            clear: both;
          }
          @media (min-width: 550px) {
            .container {
              width: 80%;
            }
            .column,
            .columns {
              width: 100%;
              float: left;
              box-sizing: border-box;
              margin-left: 4%;
              &:first-child {
                margin-left: 0;
              }
            }
            .one {
              &.column {
                width: 4.66666666667%;
              }
              &.columns {
                width: 4.66666666667%;
              }
            }
            .offset-by-one {
              &.column {
                margin-left: 8.66666666667%;
              }
              &.columns {
                margin-left: 8.66666666667%;
              }
            }
            .offset-by-two {
              &.column {
                margin-left: 17.3333333333%;
              }
              &.columns {
                margin-left: 17.3333333333%;
              }
            }
            .offset-by-three {
              &.column {
                margin-left: 26%;
              }
              &.columns {
                margin-left: 26%;
              }
            }
            .offset-by-four {
              &.column {
                margin-left: 34.6666666667%;
              }
              &.columns {
                margin-left: 34.6666666667%;
              }
            }
            .offset-by-five {
              &.column {
                margin-left: 43.3333333333%;
              }
              &.columns {
                margin-left: 43.3333333333%;
              }
            }
            .offset-by-six {
              &.column {
                margin-left: 52%;
              }
              &.columns {
                margin-left: 52%;
              }
            }
            .offset-by-seven {
              &.column {
                margin-left: 60.6666666667%;
              }
              &.columns {
                margin-left: 60.6666666667%;
              }
            }
            .offset-by-eight {
              &.column {
                margin-left: 69.3333333333%;
              }
              &.columns {
                margin-left: 69.3333333333%;
              }
            }
            .offset-by-nine {
              &.column {
                margin-left: 78%;
              }
              &.columns {
                margin-left: 78%;
              }
            }
            .offset-by-ten {
              &.column {
                margin-left: 86.6666666667%;
              }
              &.columns {
                margin-left: 86.6666666667%;
              }
            }
            .offset-by-eleven {
              &.column {
                margin-left: 95.3333333333%;
              }
              &.columns {
                margin-left: 95.3333333333%;
              }
            }
            .offset-by-one-third {
              &.column {
                margin-left: 34.6666666667%;
              }
              &.columns {
                margin-left: 34.6666666667%;
              }
            }
            .offset-by-two-thirds {
              &.column {
                margin-left: 69.3333333333%;
              }
              &.columns {
                margin-left: 69.3333333333%;
              }
            }
            .offset-by-one-half {
              &.column {
                margin-left: 52%;
              }
              &.columns {
                margin-left: 52%;
              }
            }
            .two.columns {
              width: 13.3333333333%;
            }
            .three.columns {
              width: 22%;
            }
            .four.columns {
              width: 30.6666666667%;
            }
            .five.columns {
              width: 39.3333333333%;
            }
            .six.columns {
              width: 48%;
            }
            .seven.columns {
              width: 56.6666666667%;
            }
            .eight.columns {
              width: 65.3333333333%;
            }
            .nine.columns {
              width: 74%;
            }
            .ten.columns {
              width: 82.6666666667%;
            }
            .eleven.columns {
              width: 91.3333333333%;
            }
            .twelve.columns {
              width: 100%;
              margin-left: 0;
            }
            .one-third.column {
              width: 30.6666666667%;
            }
            .two-thirds.column {
              width: 65.3333333333%;
            }
            .one-half.column {
              width: 48%;
            }
          }
          h1,
          h2,
          h3,
          h4,
          h5 {
            margin-bottom: 0.5em;
            font-family: Georgia, serif;
          }
          h2 {
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 10px;
            color: white;
            width: 60%;
            position: relative;
            display: inline-block;
            background: $exeter-bus-grey;
            margin-bottom: 60px;
            &:before {
              content: " ";
              background-repeat: no-repeat;
              background-position-x: 80%;
              position: absolute;
              background: linear-gradient(
                to right top,
                #4b4e51 calc(50% - 1px),
                #4b4e51 calc(50% - 1px),
                #4b4e51 49%,
                transparent 50%
              );
              height: 100%;
              left: 100%;
              top: 0;
              width: 30%;
              z-index: 0;
            }
            &:after {
              content: " ";
              border-bottom: 2px solid $exeter-bus-grey;
              width: 160%;
              height: 2px;
              position: absolute;
              left: 0;
              bottom: 0;
              z-index: 0;
              overflow: hidden !important;
            }
            &.adr-book-header {
              background: #fff;
              color: $exeter-bus-grey;
              font-weight: bold;
              padding-left: 0;
              width: auto;
              &:after,
              &:before {
                content: none;
              }
            }
          }
          h3 {
            border-bottom: 2px solid $exeter-bus-grey;
            color: $exeter-bus-grey;
            font-family: "Lato", sans-serif;
            font-weight: 700;
            padding: 10px 0;
            &.no-border {
              border-bottom: none;
            }
          }
          h1,
          h4 {
            color: $exeter-bus-grey;
          }

          @mixin noPseudos() {
            &::after,
            &::before {
              display: none !important;
              content: unset !important;
            }
          }

          @mixin serifHeadingOverrides {
            color: $exeter-bus-grey !important;
            margin-bottom: 0.5em !important;
            font-family: Georgia, serif !important;
            border: none !important;
            background: none !important;
            font-weight: 300 !important;
            line-height: 1.2 !important;
            padding: 0 !important;
            width: 100% !important;
            margin-bottom: 0.5rem !important;
          }

          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            &.display-as-h3 {
              @include noPseudos();
              border-bottom: 2px solid $exeter-bus-grey !important;
              color: $exeter-bus-grey !important;
              font-family: "Lato", sans-serif !important;
              font-weight: 700 !important;
              padding: 10px 0 !important;
              font-size: 1.64rem !important;
              background: none !important;
              width: 100% !important;
              margin-bottom: 0.5rem !important;

              &.no-border {
                border-bottom: none !important;
              }
            }

            &.display-as-h4 {
              @include noPseudos();
              @include serifHeadingOverrides();
              font-size: 1.41rem !important;
            }

            &.display-as-h5 {
              @include noPseudos();
              @include serifHeadingOverrides();
              font-size: 1.17rem !important;
            }

            &.display-as-h6 {
              @include noPseudos();
              font-size: 0.93rem !important;
              margin-bottom: 0.5rem !important;
              font-weight: 300 !important;
              line-height: 1.2 !important;
              background: none !important;
              border: none !important;
              padding: 0 !important;
              color: $exeter-bus-grey !important;
              font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif !important;
            }
          }

          ul {
            margin-right: 32px;
            margin-left: 32px; // border-left: 3px solid rgba(30, 144, 255, 0.6);
            // padding-left: 2.75rem;
            li {
              margin-bottom: 0.5rem;
              position: relative;
              &:last-of-type {
                margin-bottom: 0;
              }
            }
          }
          a[href="https://www.sciencedirect.com/science/book/9780123750709"]
          {
            color: #0066cc !important;
            text-decoration: none;
            &:hover {
              color: #4e89c3 !important;
              text-decoration: underline;
            }
          }
          .hero {
            margin-bottom: 20px;
            position: relative;
            z-index: 0;
            max-height: 400px !important;
            overflow: hidden;
            border-bottom: 4px solid $exeter-bus-grey;
            @media all and (max-width: 768px) {
              border-bottom: none !important;
              img {
                border-bottom: 4px solid $exeter-bus-grey;
              }
            }
            img {
              width: 100%;
            }
            h1 {
              position: absolute;
              z-index: 1;
              padding: 5px 40px 10px;
              color: white;
              line-height: 50px;
            }
            h1:after {
              content: " ";
              display: inline-block;
              position: absolute;
              z-index: -1;
              left: 0;
              top: 0;
              border-right: 60px solid transparent;
              border-top: 60px solid $exeter-bus-grey;
              height: 0;
              width: 110%;
            }
          } // video wrapper (responsive videos ONLY)
          .video-container {
            width: 600px;
            max-width: 100%;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            .video-wrapper {
              position: relative;
              padding-bottom: 56.25%;
              /* 16:9 */
              padding-top: 25px;
              height: 0;
              iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
              }
            }
          } // callouts
          .important-callout {
            border: 5px solid rgba(113, 33, 153, 0.55);
            &:before {
              content: " \f14a";
              color: rgba(113, 33, 153, 0.7);
            }
          }
          .tech-callout {
            border: 5px solid $exeter-green;
            &:before {
              content: " \f109";
              color: $exeter-green;
              margin-left: -0.1em !important;
            }
          }
          .think-about-callout {
            border: 5px solid $exeter-bus-light-blue;
            &:before {
              content: " \f0eb";
              color: $exeter-bus-light-blue;
            }
          }
          .reading-callout {
            border: 5px solid $exeter-bus-purple;
            &:before {
              content: " \f02d";
              color: $exeter-bus-purple;
            }
          }
          .important-callout,
          .tech-callout,
          .think-about-callout,
          .reading-callout {
            background: $exeter-kp-light-grey;
            padding: 1.5em 2em 0.25em 1em;
            position: relative;
            width: 75%;
            height: inherit;
            left: 50%;
            margin: 1.5em 0 1.5em -40%;
            &:before {
              font-family: "FontAwesome";
              font-size: 3em;
              line-height: 1em;
              margin-right: 0.5em;
              margin-left: 0.25em;
              vertical-align: -0.4em;
              float: left;
            }
            .callout-note {
              font-style: italic;
              text-align: center;
              font-size: 0.9rem;
              width: 50%;
              position: relative;
              left: 50%;
              margin-left: -25%;
              padding-top: 0.5rem;
              border-top: 3px solid $exeter-sr-green;
            }
            h1,
            h2,
            h3,
            h4,
            h5 {
              background: transparent;
              color: unset;
              margin: unset;
              padding: unset;
              &:before {
                background: transparent;
              }
              &:after {
                border: none;
              }
            }
            p {
              margin-left: 3.25em;
            }
            ol {
              margin-left: 2em;
            }
          }
          .video-callout:not(.kp-callout-18),
          .drive-callout:not(.kp-callout-18) {
            position: relative;
            max-width: 50%;
            min-width: 300px;
            left: 50%;
            margin-left: -25%;
            text-align: center;
            box-sizing: border-box !important;
            p.callout-link {
              display: table;
              background: #dee7bc;
              border-radius: 15px;
              -webkit-transition: background 0.5s;
              transition: background 0.5s;
              margin-left: auto;
              margin-right: auto;
              a {
                text-decoration: none !important;
              }
              &:hover {
                background: #92af21;
                cursor: pointer;
                border-radius: 15px;
                & > * {
                  color: white;
                  cursor: pointer;
                }
                a > i.fa-video-camera:after {
                  opacity: 1;
                }
              }
            }
            i.fa-video-camera,
            i.fa-folder-open {
              display: table-cell;
              vertical-align: middle;
              padding: 10px;
              color: white;
              border-top-left-radius: 15px;
              border-bottom-left-radius: 15px;
              background: #92af21;
              width: 20%;
              &:after {
                font-family: "FontAwesome";
                content: " \f04b";
                font-size: 40%;
                color: #92af21;
                position: absolute;
                margin-left: -32px !important;
                margin-top: 12px !important;
                opacity: 0;
                transition: opacity 0.4s;
              }
            }
            i.fa-folder-open:after {
              display: none;
            }
            span {
              display: table-cell;
              position: relative;
              padding: 3%;
              width: 80%;
            }
            a {
              font-weight: bold;
            }
            .callout-note {
              font-style: italic;
              padding: 10px 15px;
              min-width: 270px;
              background: #f9f9f9;
              border-radius: 15px;
              text-align: center;
            }
            .callout-transcript {
              text-align: center;
              font-size: 0.9rem;
              margin-top: -0.5rem;
            }
          }
          .drive-callout p.callout-link {
            i {
              background: $exeter-navy !important;
            }
            background: #e7e9eb !important;
            a {
              color: $exeter-navy;
            }
            &:hover {
              background: $exeter-navy !important;
            }
          }
          blockquote {
            border-width: 2px 0;
            border-top: 2px solid #004c9c;
            border-bottom: 2px solid #004c9c;
            padding: 1.5em 1em 1em 1em;
            display: block;
            margin: 2em auto 2.5em auto;
            position: relative;
            width: 80%;
            background: inherit;
            .fa {
              position: absolute;
              top: 0em;
              left: 50%;
              transform: translate(-50%, -50%);
              background: #fff;
              width: 4.5rem;
              text-align: center;
              color: #004c9c;
              font-size: 3rem;
            }
            p.quoted {
              text-align: right;
              display: block;
              padding-top: 10px;
              font-size: 0.7em;
            }
            cite {
              text-align: right;
              display: block;
              padding-top: 10px;
              font-size: 0.7em;
            }
          }
          .giz-contentarea blockquote .fa {
            background: inherit;
          }
          .kp-ctr.four.columns {
            position: relative;
            left: 50%;
            margin-left: -18%;
          }
          .wrap-left {
            float: left;
            margin: 10px 10px 10px 0;
          }
          .wrap-right {
            float: right;
            margin: 10px 0 10px 10px;
          } // image with box and description
          .image-content {
            margin: 40px 0;
            background-position: 200px 0;
            background-size: 100%;
            .title {
              background: $exeter-bus-grey url("../img/globe.jpg") no-repeat right -40px;
              color: white;
              padding: 20px;
              width: 35%;
            }
            .info {
              font-size: 30px;
              color: white;
              margin-top: 0;
              margin-bottom: 20px;
              padding: 0;
              line-height: initial;
            }
          } // audio player
          .mediaplugin.mediaplugin_videojs {
            margin: 0;
            & > div {
              max-width: unset !important;
              max-width: none !important;
              width: 100% !important;
            }
          }
          .vjs-play-control.vjs-control.vjs-button {
            margin: 0 !important;
            font-size: 1em !important;
            border-radius: 0 !important;
            box-shadow: none !important;
          }
          .audio-player {
            box-shadow: #ddd;
            margin-bottom: 40px;
            audio {
              width: 100%;
              display: block;
              background-color: #dbd9d6;
            }
            audio::-webkit-media-controls-panel {
              background-color: #dbd9d6;
            }
          }
          .audio-content {
            background: $exeter-bus-grey url("../img/globe.jpg") no-repeat right -40px !important;
            color: white;
            padding: 20px;
            h3 {
              color: white !important;
              margin-top: 0 !important;
              margin-bottom: 5px !important;
            }
            & > .label {
              font-size: 26px;
              background-color: transparent;
              display: block;
              padding: 0.1em 0;
              text-align: left;
              border-radius: unset;
              border-radius: none;
            }
            a:hover {
              color: #5d8dff;
            }
          }
          img.resource-type {
            max-width: 40px;
          } // buttons
          .button,
          button:not([class^="owl-"], [class*="atto"]),
          input[type="button"],
          input[type="reset"],
          input[type="submit"] {
            text-transform: capitalize;
            background: $exeter-blue;
            color: white;
            border-radius: 3px;
            border: none;
            margin: 20px auto;
            padding: 5px 10px;
            font-size: 22px;
            font-weight: 700;
            box-shadow: 0 3px 0 #ddd;
            text-shadow: none;
            transition: background 350ms ease-in-out;
            &:hover {
              background: $exeter-navy;
              color: white;
              transition: background 350ms ease-in-out;
            }
            &:focus {
              outline: 0;
              color: white;
            }
          }
          .forumaddnew button {
            text-transform: none !important;
          }
          // manual navigation buttons
          .manual-nav {
            text-align: center;
            width: 100%;
          }
          button.mn-btn {
            a {
              color: #fff;
              text-decoration: none;
            }
          } // interactives
          .kp-interactive {
            // drag and drop
            .dragDrop {
              margin-top: 1em;
              margin-bottom: 1em;
              .dragDropScore {
                display: none;
                width: 100%;
                text-align: center;
                background: $exeter-kp-grey;
                padding: 10px 0;
                font-weight: bold;
                margin-bottom: 2rem;
              }
              .dragContainer {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                .dragObject {
                  background: #e1e1e1;
                  border-radius: 10px;
                  cursor: pointer;
                  z-index: 1;
                  display: inline-block;
                  h4 {
                    margin-top: 15px;
                    padding: 0 15px 5px;
                    font-family: sans-serif !important;
                    text-align: center;
                    text-transform: uppercase;
                  }
                }
              }
              .dropContainer {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                margin: 0 2em;
                .dropWrapper {
                  max-width: 250px;
                  margin: 1rem;
                  flex: 1;
                  img {
                    max-height: 400px;
                    display: block;
                    margin-left: 0.5rem;
                  }
                  .dropObject {
                    position: relative;
                    display: block;
                    max-width: 100%;
                    height: 50px;
                    overflow: hidden;
                    margin: 1rem;
                    z-index: 0;
                    border: 2px dashed $exeter-bus-grey;
                    .droppedObject {
                      display: block;
                      background: $exeter-blue;
                      height: 100%;
                      width: 100%;
                      border-radius: 0;
                      padding-top: 10px;
                      h4 {
                        text-transform: uppercase !important;
                        font-family: sans-serif !important;
                        color: white !important;
                        text-align: center;
                        margin-top: 0;
                      }
                    }
                    &.dragDropCorrect,
                    &.dragDropIncorrect {
                      font-family: "FontAwesome";
                      color: white;
                      &:before {
                        position: absolute;
                        width: 20px;
                        height: 20px;
                        top: 0;
                        left: 7px;
                      }
                    }
                    &.dragDropCorrect {
                      &:before {
                        content: " \f00c";
                      }
                      .droppedObject {
                        background: $exeter-sr-green;
                      }
                    }
                    &.dragDropIncorrect {
                      &:before {
                        content: " \f00d";
                      }
                      .droppedObject {
                        background: $exeter-kp-red;
                      }
                    }
                  }
                }
              }
              &#needs {
                .droppedObject > h4 {
                  padding-top: 4px;
                  padding-left: 8px;
                  font-size: 1em;
                }
              }
              &#global-opinion {
                .dragContainer {
                  .dragObject {
                    margin: 1em;
                    h4 {
                      font-size: 1.25em;
                    }
                  }
                  margin-bottom: 2em;
                }
                .dropContainer {
                  table.exeter-table {
                    tr {
                      td {
                        text-align: center;
                        min-width: 200px;
                        .dropWrapper {
                          .dropObject {
                            width: 100%;
                            border-radius: 10px;
                            margin: 0;
                            h4 {
                              padding-top: 5px;
                              font-size: 100%;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              &#learning-theories,
              &#strong-weak-headlines {
                .dragContainer {
                  .dragObject {
                    width: 25%;
                    margin: 1em;
                    h4 {
                      font-size: 1em;
                    }
                  }
                  .dropContainer {
                    .dropWrapper {
                      .dropObject {
                        h4 {
                          margin-left: 0.5em;
                        }
                      }
                    }
                  }
                }
              }
            }
            // generic click and reveal with text area
            .clickreveal {
              padding: 20px;
              margin-bottom: 1em;
              textarea {
                width: 100% !important;
                padding: 20px;
                resize: none;
                border: 3px solid $exeter-bus-grey;
                box-sizing: border-box;
                transition: all 350ms ease-in-out;
                &:focus {
                  border-color: $exeter-sky-blue;
                  outline: none;
                  transition: all 350ms ease-in-out;
                }
              }
              .boldbox {
                background: #efefef;
                border: 3px solid $exeter-green;
                padding: 20px;
                box-shadow: 0 20px 30px #ddd;
                h5 {
                  color: $exeter-blue;
                  font-family: Georgia, serif;
                  margin-bottom: 0.8em !important;
                }
              }
              .post-reminder {
                text-align: center;
                font-weight: bold;
                button.link {
                  padding-top: 0 !important;
                  & > a {
                    color: #fff !important;
                    &:hover {
                      text-decoration: none !important;
                    }
                  }
                }
              }
            }
            #accordion,
            #accordion-1,
            #accordion-2,
            #accordion-3,
            #accordion-4,
            #accordion-5,
            #accordion-6,
            #accordion-7 {
              // basic accordion
              h3.accordion-toggle {
                background: $exeter-blue;
                color: #fff;
                padding-left: 10px;
                border-radius: 2px;
                cursor: pointer;
                margin-bottom: 3px !important;
                margin-top: 0 !important;
                border: none;
                height: initial;
                line-height: initial;
                width: 100%;
                font-size: 1.25em;
                @include clean-header;
                &.open .fa-plus {
                  -webkit-transform: rotate(224deg);
                  -moz-transform: rotate(224deg);
                  -o-transform: rotate(224deg);
                  -ms-transform: rotate(224deg);
                  transform: rotate(224deg);
                }
                .fa-plus {
                  float: right;
                  margin: 6px 12px;
                  font-size: 14px;
                  -webkit-transition: all 300ms ease-in 0s;
                  -moz-transition: all 300ms ease-in 0s;
                  -o-transition: all 300ms ease-in 0s;
                  transition: all 300ms ease-in 0s;
                }
              }
              .accordion-content {
                display: none;
                background: #fff;
                padding: 10px;
                &.default {
                  display: block;
                }
              }
            }
            .caption-style {
              // hover caption
              list-style-type: none;
              margin: 0 auto;
              padding: 0;
              max-width: 600px;
              height: 600px;
              display: block;
              li {
                float: left;
                padding: 0;
                position: relative;
                overflow: hidden;
                box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.6);
                margin: 15px 15px 15px 0;
                border: 2px solid $exeter-green;
                &:hover .caption {
                  opacity: 1;
                  transform: translateY(-300px);
                  -webkit-transform: translateY(-300px);
                  -moz-transform: translateY(-300px);
                  -ms-transform: translateY(-300px);
                  -o-transform: translateY(-300px);
                }
                &:hover .label {
                  opacity: 0;
                  transform: translateY(-300px);
                  -webkit-transform: translateY(-300px);
                  -moz-transform: translateY(-300px);
                  -ms-transform: translateY(-300px);
                  -o-transform: translateY(-300px);
                }
              }
              img {
                height: 280px;
                width: 280px;
                margin: 0;
                padding: 0;
                float: left;
                z-index: 4;
              }
              .caption {
                cursor: pointer;
                position: absolute;
                opacity: 0;
                top: 300px;
                -webkit-transition: all 0.55s ease-in-out;
                -moz-transition: all 0.55s ease-in-out;
                -o-transition: all 0.55s ease-in-out;
                -ms-transition: all 0.55s ease-in-out;
                transition: all 0.55s ease-in-out;
              }
              .label {
                background-color: rgba(55, 82, 81, 0.8);
                top: 305px;
                height: 50px;
                width: 280px;
                z-index: 5;
                opacity: 1;
                position: absolute;
                -webkit-transition: all 0.55s ease-in-out;
                -moz-transition: all 0.55s ease-in-out;
                -o-transition: all 0.55s ease-in-out;
                -ms-transition: all 0.55s ease-in-out;
                transition: all 0.55s ease-in-out;
                h2 {
                  color: #fff;
                  text-transform: uppercase;
                  font-size: 20px;
                  line-height: 20px;
                  margin: 15px 0 0 10px;
                  background: none;
                  @include clean-header;
                }
              }
              .blur {
                background-color: rgba(55, 82, 81, 0.8);
                height: 280px;
                width: 280px;
                z-index: 5;
                position: absolute;
              }
              .caption-text {
                z-index: 10;
                color: #fff;
                position: absolute;
                width: 280px;
                height: 250px;
                h2,
                h3,
                p {
                  color: #fff;
                  border: none;
                  background: transparent;
                  @include clean-header;
                }
                h2 {
                  text-transform: uppercase;
                  font-size: 20px;
                  line-height: 20px;
                  margin: 15px 0 0 10px;
                }
                h3 {
                  font-size: 16px;
                  line-height: 12px;
                  margin: 10px 0 0 10px;
                }
                p {
                  font-size: 14px;
                  line-height: 19px;
                  margin: 10px 10px 0;
                }
              }
            }
            .tab-reveal {
              margin: 2em 0;
              .reveal-pair {
                width: 25%;
                padding: 0 0 1%;
                float: left;
                &.full-width {
                  width: 100% !important;
                }
                &.half-width {
                  width: 50% !important;
                }
                .reveal-button {
                  position: relative;
                  cursor: pointer;
                  padding: 0.5em 0;
                  background: $exeter-blue;
                  margin: 1%;
                  border-radius: 15px;
                  border: 2px solid #fff;
                  text-align: center;
                  font-weight: bold;
                  h4 {
                    font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
                    padding-top: 15px;
                    margin-bottom: 20px;
                    color: #fff !important;
                    &:after {
                      position: absolute;
                      content: "";
                      width: 0;
                      height: 0;
                      border-top: 20px solid $exeter-blue;
                      border-right: 20px solid transparent;
                      border-bottom: 20px solid transparent;
                      border-left: 20px solid transparent;
                      bottom: -1.9em;
                      left: 50%;
                      margin-left: -20px;
                    }
                  }
                  .to-be-revealed {
                    display: none;
                    text-align: left;
                    background: #fff;
                    color: #000 !important;
                    font-weight: normal;
                    border-bottom-left-radius: 15px;
                    border-bottom-right-radius: 15px;
                    border: 2px solid #004c9c;
                    padding: 0;
                    cursor: text;
                    margin-bottom: -0.5em;
                    position: relative;
                    z-index: 3;
                    p {
                      margin: 0.25em 1em 1em;
                    }
                  }
                  .revealed-flex-grid {
                    display: -webkit-box;
                    display: -webkit-flex;
                    display: -ms-flexbox;
                    display: flex;
                  }
                  .revealed-flex-grid-item {
                    -webkit-box-flex: 1;
                    -webkit-flex: 1 1 100%;
                    -ms-flex: 1 1 100%;
                    flex: 1 1 100%;
                    -webkit-box-align: center;
                    -webkit-align-items: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -webkit-justify-content: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    text-align: center;
                  }
                }
              }
            }
            .tabsection {
              position: relative;
              top: 0;
              width: 100%;
              height: auto;
              background: #fff;
              color: #000;
              box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
              border: 1px solid $exeter-blue;
              @include target-clear;
              h4 {
                font-family: sans-serif;
                position: relative;
                font-size: 1em;
                line-height: 1.1em;
                background: rgba($exeter-blue, 0.3);
                cursor: pointer;
                border: 1px solid rgba($exeter-blue, 0.2);
                border-top: none;
                padding: 35px 20px;
                margin: 0;
                &:first-child {
                  border-top: 1px solid rgba(0, 0, 0, 0.2);
                }
                @media all and (min-width: 600px) {
                  position: relative;
                  width: 25%;
                  height: 12.5%;
                  display: block;
                }
              }
              ul {
                zoom: 1;
                position: relative;
                height: auto;
                min-height: 100%;
                border: 1px solid rgba(0, 0, 0, 0.2);
                border-left: none;
                display: none;
                margin: 0;
                padding: 15px;
                @include target-clear;
                @media all and (min-width: 600px) {
                  position: absolute;
                  width: 75%;
                  right: 0;
                  top: 0;
                  padding: 0 20px;
                }
              }
              p {
                margin: 30px 30px 10px;
                line-height: 1.5em;
              }
              .active {
                cursor: default;
                border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                border-right: none;
                & + ul {
                  display: block;
                }
                @media all and (min-width: 600px) {
                  background: rgba(0, 0, 0, 0);
                  border-right: 1px solid rgba(0, 0, 0, 0);
                }
              }
              @media all and (min-width: 600px) {
                width: 100%;
                margin: 10px auto;
              }
              .tabtext {
                margin: 15px 5px;
              }
            }
            .cr-slide {
              max-width: 800px;
              height: 400px;
              position: relative;
              z-index: 0;
              box-sizing: border-box;
              overflow: hidden;
              .info {
                background-color: $exeter-navy;
                background-image: -webkit-linear-gradient(30deg, lighten($exeter-navy, 5%) 50%, $exeter-navy 50%);
                color: #fff;
                padding: 20px;
                min-width: 130px;
                width: 30%;
                position: absolute;
                z-index: 2;
                font-size: 1em;
                line-height: initial;
                height: 400px;
                @media all and (max-width: 1000px) {
                  position: relative;
                  min-width: initial;
                  padding: 2% 5%;
                  height: auto;
                  width: 100%;
                  left: 0;
                }
              }
              .image-content {
                width: 100%;
                position: relative;
                margin: 0 !important;
                left: 130px;
                @media all and (max-width: 1000px) {
                  left: 0 !important;
                }
                img {
                  position: absolute;
                  z-index: 1;
                  right: 0;
                  width: 100%;
                  @media all and (max-width: 1000px) {
                    width: auto;
                    max-height: 300px;
                    left: 0 !important;
                    margin: 0 auto;
                  }
                }
              }
              .slide {
                &.inactive {
                  display: none;
                }
                &:active {
                  display: block;
                }
              }
            }
            .cr-nav button {
              font-size: 1em;
              border: none;
              border-bottom: 3px solid $exeter-navy;
              padding: 8px 20px 6px;
              cursor: pointer;
              &:hover {
                background: $exeter-bus-light-blue;
                color: #fff;
              }
              &:active {
                background: $exeter-navy;
                border-bottom: 1px solid $exeter-navy;
              }
            } //bemm148: positioning sider
            &.slider-positioning {
              #slide3 {
                width: 85%;
                top: -45px;
                @media all and (max-width: 1000px) {
                  width: 100%;
                  top: 0;
                }
              }
            }
            //uegp: mioroscope
            .microscope {
              margin: 40px 0;
              text-align: center;
            }
            .microscope-button {
              background: #a1aebb;
              font-size: 26px;
              color: #fff;
              padding: 10px;
              border-radius: 10px;
              text-align: center;
              margin-bottom: 20px;
              cursor: pointer;
              &.current {
                background: #004c9c;
                cursor: default;
              }
            }
            .microscope-content {
              background: #f1f1f1;
              padding: 30px;
              border-radius: 10px;
              color: #333;
              font-size: 17px;
              line-height: 22px;
            }
            // uegp: 1000 people
            .reveal-game {
              .item {
                figure {
                  position: relative;
                  overflow: hidden;
                  margin: 10px 1%;
                  background: #000;
                  text-align: center;
                  cursor: pointer;
                  figcaption {
                    padding: 15px;
                    color: #fff;
                    backface-visibility: hidden;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    text-align: left;
                    &:before,
                    &:after {
                      pointer-events: none;
                    }
                    div {
                      position: absolute;
                      top: 0;
                      left: 0;
                      padding: 15px;
                      width: 100%;
                      height: 50%;
                    }
                  }
                }
                a {
                  display: block;
                  .title {
                    font-size: 20px;
                    line-height: normal;
                    padding-right: 15px;
                    transition: transform 0.35s;
                  }
                  p {
                    font-size: 80px;
                    opacity: 0;
                    transition: opacity 0.2s, transform 0.35s;
                  }
                  .title,
                  p {
                    transform: translate3d(0, 40px, 0);
                  }
                  img {
                    max-width: none !important;
                    width: calc(100% + 50px);
                    opacity: 0.3;
                    transition: opacity 0.35s, transform 0.35s;
                    transform: translate3d(-40px, 0, 0);
                  }
                  &:hover,
                  &:focus {
                    img,
                    .title,
                    p {
                      transform: translate3d(0, 0, 0);
                    }
                    img {
                      opacity: 0.6;
                    }
                    p {
                      opacity: 1;
                    }
                  }
                }
              }
              img {
                max-width: 100%;
              }
              button {
                font-size: 16px;
                margin: 0;
                box-shadow: none;
                padding: 3px 10px;
                width: 40%;
                float: left;
              }
              @media screen and (max-width: 576px) {
                .item a p {
                  line-height: 80px;
                }
              }
            }
            // bemm166 brand element quiz
            .brandElementQuiz {
              .brandContainer {
                border-bottom: 1px dashed $exeter-bus-grey;
                margin-bottom: 1rem;

                &#question0 {
                  .twoColors {
                    .firstColor,
                    .secondColor {
                      float: left;
                      height: 200px;
                      width: 50%;
                    }

                    .firstColor {
                      background: #1a1f71;
                    }

                    .secondColor {
                      background: #fdbb0a;
                    }
                  }
                }

                &#question1 {
                  .twoColors {
                    .firstColor,
                    .secondColor {
                      float: left;
                      height: 200px;
                      width: 50%;
                    }

                    .firstColor {
                      background: #ff9900;
                    }

                    .secondColor {
                      background: #146eb4;
                    }
                  }
                }

                &#question2 {
                  .twoColors {
                    .firstColor,
                    .secondColor {
                      float: left;
                      height: 200px;
                      width: 50%;
                    }

                    .firstColor {
                      background: #003399;
                    }

                    .secondColor {
                      background: #ffcc00;
                    }
                  }
                }

                &#question3 {
                  .twoColors {
                    .firstColor,
                    .secondColor {
                      float: left;
                      height: 200px;
                      width: 50%;
                    }

                    .firstColor {
                      background: #003087;
                    }

                    .secondColor {
                      background: #009cde;
                    }
                  }
                }

                &#question4 {
                  .twoColors {
                    .firstColor,
                    .secondColor {
                      float: left;
                      height: 200px;
                      width: 50%;
                    }

                    .firstColor {
                      background: #000;
                    }

                    .secondColor {
                      background: #00592d;
                    }
                  }
                }

                &#question5 {
                  .twoColors {
                    .firstColor,
                    .secondColor {
                      float: left;
                      height: 200px;
                      width: 50%;
                    }

                    .firstColor {
                      background: #550f9d;
                    }

                    .secondColor {
                      background: #b9861f;
                    }
                  }
                }

                &#question6 {
                  .twoColors {
                    .firstColor,
                    .secondColor {
                      float: left;
                      height: 200px;
                      width: 50%;
                    }

                    .firstColor {
                      background: #f0b823;
                    }

                    .secondColor {
                      background: #000;
                    }
                  }
                }

                &#question7 {
                  .twoColors {
                    .firstColor,
                    .secondColor {
                      float: left;
                      height: 200px;
                      width: 50%;
                    }

                    .firstColor {
                      background: #ffd60d;
                    }

                    .secondColor {
                      background: #000;
                    }
                  }
                }

                &#question8 {
                  .twoColors {
                    .firstColor,
                    .secondColor {
                      float: left;
                      height: 200px;
                      width: 50%;
                    }

                    .firstColor {
                      background: #660099;
                    }

                    .secondColor {
                      background: #ff6600;
                    }
                  }
                }

                &#question9 {
                  .twoColors {
                    .firstColor,
                    .secondColor {
                      float: left;
                      height: 200px;
                      width: 50%;
                    }

                    .firstColor {
                      background: #dd1021;
                    }

                    .secondColor {
                      background: #ffc300;
                    }
                  }
                }

                &#question10 {
                  .twoColors {
                    .firstColor,
                    .secondColor {
                      float: left;
                      height: 200px;
                      width: 50%;
                    }

                    .firstColor {
                      background: #fff;
                      box-shadow: inset 0 0 2px #989898;
                    }

                    .secondColor {
                      background: #989898;
                    }
                  }
                }

                & > .hints {
                  display: flex;
                  justify-content: space-between;

                  & > .crypticClue,
                  & > .twoColors,
                  & > .visualHint {
                    max-width: 33%;
                    text-align: center;
                  }
                }

                h4 {
                  text-align: center;
                  font-family: sans-serif !important;
                  text-transform: uppercase;
                  background: lighten($exeter-kp-grey, 5%);
                  padding: 10px;
                  border: 2px solid white;
                }

                .brandAnswer {
                  margin: 2rem 0;
                  width: 100%;

                  .answerBox,
                  .answerReveal {
                    h5 {
                      background: lighten($exeter-kp-grey, 5%);
                      color: $exeter-bus-grey;
                      width: 200px;
                      padding: 10px;
                      margin: 0 auto;
                      font-family: sans-serif !important;
                      text-transform: uppercase;
                    }
                  }

                  .answerBox {
                    position: relative;
                    text-align: center;

                    input {
                      height: 3rem;
                      width: 30%;
                      text-align: center;
                      border-radius: 5px;
                      border: 2px solid $exeter-sky-blue;

                      &:focus,
                      &:hover {
                        border: 2px solid $exeter-blue;
                      }
                    }
                  }

                  .answerReveal {
                    margin: 0 auto 1rem;
                    width: 300px;
                    text-align: center;
                    display: none;

                    &:before {
                      font-family: "FontAwesome";
                      position: relative;
                      float: left;
                      width: 50px;
                      padding-left: 10px;
                      padding-right: 10px;
                      color: white;
                    }

                    &.isCorrect:before {
                      padding-top: 16px;
                      padding-bottom: 15px;
                      content: " \f00c";
                      background: #2fb675;
                    }

                    &.isNotCorrect:before {
                      padding-top: 15px;
                      padding-bottom: 15px;
                      content: " \f00d";
                      background: #ff4c4c;
                    }
                  }
                }
              }
            }
            // bemm166: perceptual brands map
            #perceptual-brands {
              border: 3px dotted #818385;
              padding: 10px;
              border-radius: 10px;
              .graph-map {
                width: 538px;
                height: 476px;
                background: url("https://keypath.exeter.ac.uk/img/courses/bemm166/unit3/perceptual_map.jpg") no-repeat;
                .draggable {
                  cursor: pointer;
                }
              }
            }
            #loss-aversion {
              .la-row {
                padding: 10px;
                border-bottom: 2px dotted #dbd9d6;
                text-align: center;

                .col {
                  padding: 0;
                  margin: 0;
                }

                &.title-row {
                  background: #004c9c !important;
                  text-transform: uppercase;
                  padding: 0;
                  text-align: left;

                  h6 {
                    padding: 10px;
                    font-weight: bolder;
                    font-size: 1.5em;
                    color: white;
                    background: #004c9c;
                    margin-bottom: 0;
                  }
                }

                input.bet-add {
                  cursor: pointer;
                }

                &.results-row {
                  border-bottom: none;
                  padding-top: 1em;
                  button {
                    margin-top: 2.5em;
                  }
                  .bar-graph {
                    white-space: nowrap;
                    width: 100%;
                    max-width: 100%;
                    margin-bottom: 1em;
                    overflow: hidden;
                    height: 150px;
                    .percent-accept,
                    .percent-reject {
                      display: inline-block;
                      height: 100%;
                      text-align: center;
                      p {
                        margin-top: 0.25em;
                        font-size: 3.75em;
                        font-weight: bold;
                        color: white;
                        width: 100%;
                      }
                    }
                    .percent-accept {
                      background: #37874a;
                    }
                    .percent-reject {
                      background: #b72f3b;
                    }
                  }
                  p.loss-averse {
                    font-size: 2em;
                    font-weight: bold;
                  }
                  .explanation {
                    display: none;
                  }
                }

                .coin {
                  position: relative;
                  width: 10em;
                  height: 10em;
                  float: right;
                  border-radius: 100%;
                  background: #4d4e4f;

                  &:before {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    height: 100%;
                    content: "";
                    border-radius: 100%;
                    border: 1.25em #67686a solid;
                    transform: translate(-50%, -50%);
                  }

                  &:after {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 100%;
                    height: 100%;
                    content: "";
                    border: 1em #818285 solid;
                    border-radius: 100%;
                    transform: translate(-50%, -50%);
                  }

                  span {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    color: #818285;
                    font-size: 5em;
                    font-family: impact;
                  }

                  &.spinning {
                    animation: spin 0.3s ease-in-out 5;
                  }
                }
              }
            }
            @keyframes spin {
              0% {
                transform: rotateX(0deg);
              }

              50% {
                transform: rotateX(180deg);
              }

              100% {
                transform: rotateX(360deg);
              }
            }
            table.fillable {
              width: 100%;
              .input-text.datainput,
              button.complete-field {
                &.hide {
                  display: none;
                }
              }
              .output-text {
                display: inline-block;
              }
              button.reset {
                margin: 0 0 0 10px !important;
                background: none !important;
                border: none !important;
                box-shadow: none !important;
                cursor: pointer;
                i.fa.fa-close {
                  color: black;
                }
              }
            }
            button {
              &:focus {
                outline: 2px dotted $exeter-blue;
              }
              cursor: pointer;
              &.complete-field {
                max-height: 50px;
                padding: 2px 10px;
              }
              &.fillable-table#start-over {
                float: left;
                margin-right: 10px;
              }
            }
          }
          .piece-container {
            & > * {
              border-bottom: 2px dotted #ddd;
              margin: 1rem 0 2rem;
              padding: 2rem 1rem;
            }
            & > *:last-child {
              border-bottom: none;
            }
            .side-by-side {
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -ms-flex-wrap: wrap;
              flex-wrap: wrap;
              -ms-flex-pack: distribute;
              justify-content: space-around;
              margin: 1rem -1rem;
              padding: 1rem 0;
              .side {
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
                padding: 1rem;
                width: 50%;
                img {
                  max-width: 100%;
                  width: 100%;
                  & + p {
                    background: #ddd;
                    padding: 1rem;
                    text-align: center;
                    width: 100%;
                  }
                }
              }
            }
            .media img {
              max-width: 250px;
              padding: 0 1.5rem 1rem;
            }
          }
          main {
            height: 100vh;
            .panel-frame {
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              height: 100%;
              max-width: 100%;
              position: relative;
              -webkit-box-orient: vertical;
              -webkit-box-direction: normal;
              -ms-flex-direction: column;
              flex-direction: column;
              .image-panel {
                cursor: pointer;
                -webkit-box-flex: 1;
                -ms-flex: 1;
                flex: 1;
                overflow: hidden;
                position: relative;
                -webkit-transition: 350ms all ease-in-out;
                transition: 350ms all ease-in-out;
                margin: 0.5rem 0;
                min-height: 100px;
                width: 100%;
                &::after {
                  -webkit-transform: scale(1, 1, 1);
                  transform: scale(1, 1, 1);
                  background: rgba(0, 77, 157, 0.6);
                  content: "";
                  display: block;
                  height: 100%;
                  left: 0;
                  position: absolute;
                  top: 0;
                  -webkit-transition: -webkit-transform 450ms ease-in-out;
                  transition: -webkit-transform 450ms ease-in-out;
                  transition: transform 450ms ease-in-out;
                  transition: transform 450ms ease-in-out, -webkit-transform 450ms ease-in-out;
                  -webkit-transform-origin: center;
                  transform-origin: center;
                  width: 100%;
                }
                img {
                  margin-bottom: -5px;
                }
                .content-frame {
                  background-color: rgba(0, 77, 157, 0.85);
                  bottom: 0;
                  color: #fff;
                  font-size: 16px;
                  max-width: 0;
                  opacity: 1;
                  overflow: hidden;
                  padding: 0;
                  position: absolute;
                  right: 0;
                  width: 100%;
                  h2 {
                    @include clean-header;
                    font-size: 3rem;
                    line-height: 1.25;
                    margin-bottom: 0;
                  }
                  ul {
                    list-style-type: disc;
                    list-style-position: inside;
                    li {
                      list-style-type: disc;
                      list-style-position: inside;
                      text-indent: -22px;
                      padding-left: 20px;
                      font-size: 16px;
                    }
                  }
                }
                &.open {
                  border: 3px solid #000054;
                  cursor: auto;
                  -webkit-box-flex: 8;
                  -ms-flex-positive: 8;
                  flex-grow: 8;
                  -webkit-transition: 350ms all ease-in-out;
                  transition: 350ms all ease-in-out;
                  .content-frame {
                    padding: 2rem 4rem 1rem;
                    max-width: 100%;
                  }
                  &::after {
                    -webkit-transform: scale3d(0, 0, 0);
                    transform: scale3d(0, 0, 0);
                    -webkit-transition: -webkit-transform 450ms ease-in-out;
                    transition: -webkit-transform 450ms ease-in-out;
                    transition: transform 450ms ease-in-out;
                    transition: transform 450ms ease-in-out, -webkit-transform 450ms ease-in-out;
                    -webkit-transform-origin: center;
                    transform-origin: center;
                  }
                }
              }
            }
          }
          @media only screen and (max-width: 800px) {
            main .panel-frame .image-panel .content-frame {
              border-top-left-radius: 0;
            }
          }
          @media only screen and (max-width: 800px) {
            main .panel-frame .image-panel.open {
              border-left: none;
              border-right: none;
              .content-frame {
                max-width: 100%;
                width: 100%;
              }
            }
          }
          .accordion-trigger {
            align-items: center;
            display: flex;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 1rem;
            text-align: center;
            h2 {
              @extend .clean-header;
              text-align: center;
            }
            .fa {
              cursor: pointer;
              display: inline-block;
              font-size: 30px;
            }
          }
          .content-frame {
            overflow: hidden;
            transition: all 350ms ease-in-out;
            h2 {
              background: none;
              @extend .clean-header;
            }
            .content {
              margin: 0;
              max-height: 0;
              opacity: 0;
              transition: all 350ms ease-in-out;
            }
            &.open .content {
              margin-bottom: 0.75rem;
              margin-top: 1rem;
              max-height: 1000px;
              opacity: 1;
              transition: all 350ms ease-in-out;
            }
            .fa {
              color: #090;
            }
            .fa-times-circle-o {
              color: #f00;
            }
          }
          .image-panel {
            .floating-title {
              color: #fff;
              height: 100%;
              position: relative;
              text-align: center;
              z-index: 100;
              width: 100%;
              h2 {
                @include clean-header;
                background: none;
                left: 50%;
                margin-top: 0.5rem;
                position: absolute;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
                width: auto;
              }
            }
            &.open .floating-title {
              opacity: 0;
            }
          }
          .book_content {
            h3 {
              background: #fff;
              color: $exeter-bus-grey;
              font-weight: bold;
              padding-left: 0;
              width: auto;
              &:after,
              &:before {
                content: none;
              }
            }
          }
        }
      }
    }
  }
  &.course-6858,
  &.course-6982,
  &.course-7092,
  &.course-7149,
  &.course-7093,
  &.course-7220,
  &.course-7277,
  &.course-7321,
  &.course-6857 {
    // Induction
    .grid-wrapper {
      background-color: #ffffff;
      max-width: 600px;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: 75px;
      position: relative;
      @include target-clear;
      .grid-row {
        height: 175px;
        .square-wrapper {
          float: left;
          width: 200px;
          height: 175px;
          z-index: 3;
          @media all and (max-width: 768px) {
            clear: both;
            position: relative;
            left: 50%;
            margin-left: -100px;
            @include target-clear;
          }
          .square {
            -webkit-transition: -webkit-transform 0.1s;
            -moz-transition: -moz-transform 0.1s;
            -ms-transition: -ms-transform 0.1s;
            transition: transform 0.1s;
            width: 100%;
            height: 100%;
            &:hover {
              -webkit-transform: scale(1.5);
              -moz-transform: scale(1.5);
              -ms-transform: scale(1.5);
              transform: scale(1.5);
              -webkit-transition: -webkit-transform 0.25s;
              -moz-transition: -moz-transform 0.25s;
              -ms-transition: -ms-transform 0.25s;
              transition: transform 0.25s;
              box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.7);
              cursor: pointer;
              @media all and (max-width: 768px) {
                -webkit-transform: scale(1.5) translate(0px, -20px);
                -moz-transform: scale(1.5) translate(0px, -20px);
                -ms-transform: scale(1.5) translate(0px, -20px);
                transform: scale(1.5) translate(0px, -20px);
              }
            }
            &.red {
              background: #ea522b;
            }
            &.green {
              background: #35a801;
            }
            &.blue {
              background: #2fa1ef;
            }
            &.yellow {
              background: #ff9401;
            }
            &.purple {
              background: #8f40c2;
            }
            .starttext {
              padding: 15px;
              display: block;
              font-size: 18px;
              line-height: 22px;
              color: #fff;
            }
            &:hover .starttext {
              display: none;
            }
            .hovertext {
              padding: 15px;
              display: none;
              font-size: 9px;
              line-height: 12px;
              color: #fff;
            }
            &:hover .hovertext {
              display: block;
            }
          }
        }
      }
    }
  }

  &.course-6738,
  &.course-6980,
  &.course-7222 {
    // BEMM148
    // circle diagram
    .circle-container {
      .leftside,
      .rightside {
        position: relative;
        color: $exeter-blue;
        font-size: 1.375em;
        text-align: right;
        @media all and (max-width: 768px) {
          font-size: 1em;
        }
        &:after {
          content: "";
          background: url("../img/arrow.png");
          width: 100px;
          height: 100px;
          background-size: contain;
          position: absolute;
          top: 0;
          @media all and (max-width: 768px) {
            width: 30px;
            height: 30px;
          }
        }
      }
      .leftside:after {
        right: -100px;
        @media all and (max-width: 768px) {
          right: -50px !important;
        }
      }
      .rightside:after {
        right: 100px;
      }
      .circle-section {
        position: relative;
        margin-left: 50px;
        .circle {
          position: relative;
          float: left;
          color: white;
          font-size: 1.875em;
          opacity: 0.88;
          padding-top: 100px;
          width: 50%;
          height: auto;
          padding-bottom: 30%;
          border-radius: 50%;
          text-align: center;
          cursor: pointer;
          p {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            margin: 0;
          }
        }
        .circle1 {
          background: #333333;
        }
        .circle2 {
          background: #757b82;
        }
        .circle3 {
          background: #8f959d;
        }
        .circle4 {
          background: #b3b8be;
        }
        .circle-middle {
          position: absolute;
          top: 38%;
          left: 36%;
          padding-top: 24px;
          font-size: 20px;
          line-height: 30px;
          background: $exeter-blue;
          width: 120px;
          height: 120px;
          color: #fff;
          border-radius: 50%;
          text-align: center;
          border: 5px solid #fff;
          z-index: 9;
          @media all and (max-width: 768px) {
            top: 35%;
            left: 30%;
            padding-top: 15px;
            font-size: 16px;
            line-height: 28px;
            width: 100px;
            height: 100px;
          }
        }
      }
      .content {
        position: absolute;
        background: #fff;
        margin: -450px auto 0;
        width: 300px;
        font-size: 18px;
        padding: 20px;
        border: 5px solid $exeter-bus-grey;
        border-radius: 12px;
        box-shadow: 0 0 30px #666;
        z-index: 20;
        h2 {
          background: transparent !important;
          color: rgb(58, 58, 58) !important;
          @include clean-header;
        }
        ul {
          padding-left: 20px;
        }
        .close-icon {
          position: absolute;
          top: -20px;
          right: -20px;
          background: url("../img/close.png");
          width: 48px;
          height: 48px;
          cursor: pointer;
        }
      }
    }
  }
  .clearfix {
    &:after {
      content: " ";
      display: table;
      clear: both;
    }
  }
}

figure.img-caption-wrapper {
  display: inline-block;
  position: relative;
  img {
    max-width: 100%;
    margin-bottom: -3px;
    width: 100%;
  }
  figcaption {
    background: #ebebeb;
    box-sizing: border-box;
    bottom: 0;
    color: #004c9c;
    display: block;
    padding: 1rem;
    width: 100%;
  }
}

ul {
  &.choices {
    li {
      &.clearfloat {
        display: none;
      }
    }
  }
}

.cnr-flexbox {
  display: flex;
  font-family: Arial, sans-serif !important;
  margin: 0.5rem auto;
  perspective: 2800px;
  transform-style: preserve-3d;
  max-width: 100%;
  width: 100%;
  .cnr-exeter {
    cursor: pointer;
    margin: 0 1rem;
    min-height: 250px;
    position: relative;
    transform: rotateX(0deg);
    transform-style: preserve-3d;
    width: 33.33%;
    .flipped-content {
      height: 100%;
      min-height: 250px;
      position: relative;
    }
    &.flipped {
      .image-frame {
        transform: rotateX(180deg);
        transition: all 500ms ease-in-out;
      }
      .content {
        transform: rotateX(0deg);
        transition: all 500ms ease-in-out;
      }
    }
    .image-frame {
      align-items: center;
      backface-visibility: hidden;
      background: #ccc;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
      display: flex;
      height: 100%;
      min-height: 250px;
      transition: all 500ms ease-in-out;
    }
    img {
      max-width: 100%;
      position: relative;
      z-index: 20;
    }
    .content {
      align-items: center;
      backface-visibility: hidden;
      background-color: #16243d;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
      color: #fff;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      padding: 0 2rem;
      position: absolute;
      transition: all 500ms ease-in-out;
      bottom: 0;
      transform: rotateX(-180deg);
      width: 100%;
      z-index: 10;
      p {
        color: #fff;
        font-size: 1rem;
        margin-bottom: 0;
        position: relative;
      }
    }
    p + p {
      margin-top: 1rem;
    }
    h1.title {
      bottom: 0;
      color: #16243d;
      cursor: default;
      font-family: Lato;
      font-size: 1.75rem;
      left: 0;
      width: 100%;
      z-index: 100;
      text-align: center;
    }
  }
}

@media all and (max-width: 660px) {
  .cnr-flexbox {
    flex-wrap: wrap;
  }
  .cnr-exeter {
    margin: 1rem auto;
    max-width: 450px;
    width: 95%;
  }
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  // because IE (10 and above)
  body.pagelayout-course {
    &.course-6858,
    &.course-6982,
    &.course-7092,
    &.course-7149,
    &.course-7093,
    &.course-7220,
    &.course-6857 {
      .course-content {
        .single-section {
          .summary {
            margin-top: -47px !important;
          }
        }
      }
    }
    &.category-139 {
      .course-content {
        .single-section {
          .summary {
            margin-top: -347px !important;
          }
        }
      }
    }
    &.category-139 {
      .course-content {
        .single-section {
          .summary {
            clear: both;
            @media all and (max-width: 768px) {
              margin-top: 10px !important;
            }
          }
          .section-navigation {
            h3.sectionname {
              margin-top: 0 !important;
              top: 52px;
              &:before {
                background: linear-gradient(
                  to right bottom,
                  #4b4e51 calc(50% - 1px),
                  #4b4e51 calc(50% - 1px),
                  #4b4e51 49%,
                  transparent 50%
                ) !important;
              }
            }
          }
        }
      }
    }
  }
}

body.pagelayout-incourse {
  #page {
    #page-content.row {
      div[role="main"] {
        #ouwiki_belowtabs {
          #ouwiki_addnew {
            ul {
              margin-left: 0 !important;
              padding-left: 0 !important;
              li {
                &::before {
                  content: none;
                }
              }
            }
          }
          .ouwiki-content {
            div.ouw_belowmainhead {
              border-color: #fff;
            }

            div.ouw_topheading {
              background: #fff;
              padding: 4px 8px 8px 0px;
            }
          }
        }

        #ouwiki_indexlinks {
          ul {
            li {
              &::before {
                content: none;
              }
              a {
                padding: 6px 8px;
              }
            }
          }
        }
        .origin-location {
          animation: fadeIn linear 3s;
          -webkit-animation: fadeIn linear 3s;
          -moz-animation: fadeIn linear 3s;
          -o-animation: fadeIn linear 3s;
          -ms-animation: fadeIn linear 3s;
          @keyframes fadeIn {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
          .fa-location-arrow {
            color: #4b4e51;
          }
        }
      }
    }
  }
}
.btcwdgt-header {
  & > h2 {
    &:after,
    &:before {
      height: 0 !important;
      width: 0 !important;
      opacity: 0 !important;
      display: none !important;
    }
  }
}
// fix for course headers where you are not enroled
#page-enrol-index {
  h3.coursename > a {
    color: #fff !important;
  }
}

// Extension Activity flag May 2021, implemented by Will

.extension-activity-flag-span {
  text-indent: 0;
  background: #ffdd9e;
  font-size: 0.8em;
  padding: 0.5em 0.5em 0.6em;
  line-height: 1;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  display: inline-block;
  color: #81580d;
  position: relative;
  margin-left: 15px;
}

.extension-activity-flag-span::after {
  content: "";
  position: absolute;
  right: 100%;
  width: 0;
  height: 0;
  top: 50%;
  transform: translateY(-50%);
  border-width: 12.5px;
  border-style: solid;
  border-color: transparent;
  border-right-color: #ffdd9e;
}

body.pagelayout-incourse.category-139 #page section#region-main div[role="main"] h2.extension-activity-main-title {
  $extension-header-color: #4077af;

  background-color: $extension-header-color;

  &::before {
    background: linear-gradient(
      to right top,
      $extension-header-color calc(50% - 1px),
      $extension-header-color calc(50% - 1px),
      $extension-header-color 49%,
      transparent 50%
    );
  }

  &::after {
    border-bottom-color: $extension-header-color;
  }
}

// fix bug in moodle where text overlaps checkboxes in activity list

.path-course-view li.activity > div {
  padding-right: 60px;
}

//prevent x-overflow on editor
[data-fieldtype="editor"] > div {
  max-width: 100%;
}

//fix bug where grading table overflows and it's hidden
.gradingtable {
  overflow: auto;
}
