/* Exeter Gizmo CSS */
// @import url("https://keypath.exeter.ac.uk/assets/gizmo/beta/gizmo_exeter.css");

// Tabs fixes for a11y
&.pagelayout-incourse {
  #page {
    #region-main {
      div[role="main"] {
        .gizmo-container {
          ul {
            margin: 0;
            padding: 0;
          }

          li {
            margin-bottom: 0;

            &:before {
              content: none;
            }
          }

          a {
            // color: #fff;
            //display: block;

            &.giz-checkpoint-h-button {
              color: initial;

              // Gizmo text active fix
              &[aria-selected="true"] {
                color: #fff;
              }
            }

            &.giz-tabs-rounded-h-button {
              display: flex;
            }

            &:hover {
              // color: #fff;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

/* Exeter-specific Gizmo Fixes */
.gizmo-container.exeter {
  &.giz-checkpoint-h {
    .giz-checkpoint-h-content {
      blockquote {
        background: #fff !important;
        color: #000;
      }
      .kp-ex-media-content {
        display: flex !important;
        p {
          line-height: initial !important;
        }
      }
      .kp-ex-media-content > * {
        display: block !important;
      }
      .kp-ex-hero,
      .kp-ex-hero > * {
        display: block !important;
      }
    }
  }
  &.giz-flipcard-wrapper {
    .giz-flipcard-front {
      .inserted-image {
        height: 100%;
        width: 100%;
        overflow: hidden;
        text-align: center;
        img {
          max-height: 80%;
          display: inline-block;
        }
      }
    }
  }
  &.giz-matching-game-1,
  .giz-matching-game-2,
  .giz-matching-game-3 {
    .giz-matching-game-1-submit,
    .giz-matching-game-2-submit,
    .giz-matching-game-3-submit {
      text-transform: capitalize;
      background: #004c9c;
      color: white;
      border-radius: 3px;
      border: none;
      margin: 20px auto;
      padding: 5px 10px;
      font-size: 22px;
      font-weight: 700;
      box-shadow: 0 3px 0 #ddd;
      text-shadow: none;
      transition: background 350ms ease-in-out;
      &:hover {
        background: #16243d;
        color: white;
        transition: background 350ms ease-in-out;
      }
    }
  }
  &.giz-matching-game-group {
    .giz-matching-game-group-submit,
    .giz-matching-game-group-retry,
    .giz-matching-game-group-answer-btn {
      color: #fff !important;
      font-weight: bold;
    }
    .giz-matching-game-group-correct-feedback,
    .giz-matching-game-group-incorrect-feedback {
      float: none !important;
    }
  }
}
