#def-heading {
  text-align: center;
}
#main-def {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}

#main-def-receiver {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
}
.textarea-table-container table {
  width: 100%;
}
td textarea {
  resize: none;
  height: 100%;
  width: 100%;
}
.textarea-header-container th {
  color: white;
  width: 30%;
  padding-left: 2%;
}
#stimuli-header {
  background-color: #0097ce;
}
#processing-header,
#processing-subheader1,
#processing-subheader2,
#processing-subheader3,
#processing-subheader4,
#processing-subheader5 {
  background-color: #124866;
}
#response-header {
  background-color: #53605c;
}
#stimuli-txtarea {
  background-color: #0097ce38;
}
#processing-txtarea,
#processing1-txtarea,
#processing2-txtarea,
#processing3-txtarea,
#processing4-txtarea,
#processing5-txtarea {
  background-color: #12486647;
}
#response-txtarea {
  background-color: #53605c40;
}
#extract-btn {
  position: absolute;
  top: 100%;
  right: 2%;
  margin: 10px;
}
.textarea-table-container-1 table tr th {
  text-align: center;
}
