.uoe-presenter,
.uoe-presenter .slide-container .uoe-slide .uoe-slide-frame {
  height: 100%;
  position: relative;
}

.uoe-container {
  margin: 0 auto;
  max-width: 1170px;
}

.uoe-presenter {
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  overflow: hidden;
  width: 100%;
}

.uoe-presenter img {
  max-width: 100%;
  width: 100%;
}

.uoe-presenter .slide-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  min-height: 85vh;
  padding-bottom: 75px;
  width: 100%;
}

.uoe-presenter .slide-container .uoe-slide {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
}

.uoe-presenter .slide-container .uoe-slide a,
.uoe-presenter .slide-container .uoe-slide-frame .kp-callout-18.centered {
  transform: translateY(50%);
  width: 90% !important;
}

.uoe-presenter .slide-container .uoe-slide a,
.uoe-presenter .slide-container .uoe-slide-frame .kp-callout-18 .callout-content p {
  color: #333;
}

.uoe-presenter .slide-container .uoe-slide a,
.uoe-presenter .slide-container .uoe-slide h1,
.uoe-presenter .slide-container .uoe-slide h2,
.uoe-presenter .slide-container .uoe-slide h3,
.uoe-presenter .slide-container .uoe-slide h4,
.uoe-presenter .slide-container .uoe-slide h5,
.uoe-presenter .slide-container .uoe-slide h6,
.uoe-presenter .slide-container .uoe-slide li,
.uoe-presenter .slide-container .uoe-slide p,
.uoe-presenter .slide-container .uoe-slide span {
  color: #004c9c;
}

.uoe-presenter .slide-container .uoe-slide h1 {
  font-size: 32px;
}

.uoe-presenter .slide-container .uoe-slide h1.uoe-title-medium {
  font-size: 28px;
  text-align: center;
}

.uoe-presenter .slide-container .uoe-slide h1.white {
  color: #fff;
}

.uoe-presenter .slide-container .uoe-slide p {
  font-size: 18px;
}

.uoe-presenter .slide-container .uoe-slide p:last-of-type {
  margin-bottom: 0;
}

@media only screen and (max-width: 700px) {
  .uoe-presenter .slide-container {
    height: 100%;
    min-height: 100vh;
    max-height: 100vh;
  }
  .uoe-presenter .slide-container .uoe-slide h1.uoe-title-medium {
    font-size: 2.5rem;
  }
  .uoe-presenter .slide-container .uoe-slide p {
    font-size: 18px;
  }
}

.uoe-presenter .slide-container .uoe-slide ol,
.uoe-presenter .slide-container .uoe-slide ul {
  font-size: 18px;
  list-style-position: inside;
  margin-left: 22px;
  text-indent: -36px;
}

.uoe-presenter .attention-text {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 166, 182, 0.25);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 6rem;
  text-align: center;
  width: 50%;
}

.uoe-presenter .attention-text h1 {
  margin: 0;
  padding: 0 2rem;
}

.uoe-presenter .attention-text.circle {
  border-radius: 50%;
  margin-left: -5rem;
}

@media only screen and (max-width: 700px) {
  .uoe-presenter .slide-container .uoe-slide ol li,
  .uoe-presenter .slide-container .uoe-slide ul li {
    font-size: 16px;
  }
  .uoe-presenter .attention-text {
    background-color: rgba(0, 166, 182, 0.85);
    width: 100%;
  }
  .uoe-presenter .attention-text.circle {
    margin-left: 0;
  }
}

.uoe-presenter .content-area {
  background-color: #e6e6e6;
  padding: 3rem 4rem;
}

@media only screen and (max-width: 700px) {
  .uoe-presenter .content-area {
    width: 100%;
  }
}

.uoe-presenter .content-area.full-width {
  margin: 0 2rem;
  width: auto;
}

.uoe-presenter .content-area.bottom {
  bottom: 14%;
  position: absolute;
}

@media only screen and (max-width: 500px) {
  .uoe-presenter .content-area.bottom {
    bottom: 18%;
    font-size: 3rem;
  }
}

.uoe-presenter .presenter-controls {
  background-color: rgba(0, 77, 157, 0.85);
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100px;
  left: 0;
  padding: 2rem;
  position: absolute;
  right: 0;
}

.uoe-presenter .presenter-controls button.uoe-btn {
  background: grey !important;
  border: none !important;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23) !important;
  color: #fff;
  font-size: 22px;
  line-height: 1;
  margin: 0 !important;
  padding: 10px 30px !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.uoe-presenter .presenter-controls button.uoe-btn:hover {
  -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.uoe-presenter .presenter-controls .uoe-btn.next-button {
  margin-left: auto !important;
}

.uoe-tab-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 3rem;
  position: relative;
}

@media only screen and (max-width: 700px) {
  .uoe-tab-container {
    margin: 3rem 2rem;
  }
}

.uoe-tab-container .uoe-tabs {
  width: 30%;
}

.uoe-tab-container .uoe-tab {
  background-color: #005e86;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  margin: 0.5rem 0;
  padding: 1rem 2rem;
  text-align: center;
  -webkit-transition: 350ms all ease-in-out;
  transition: 350ms all ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media only screen and (max-width: 700px) {
  .uoe-tab-container .uoe-tab {
    font-size: 16px;
    padding: 2rem 0;
  }
}

.uoe-tab-container .uoe-tab:hover {
  background-color: #007db3;
  -webkit-transition: 350ms all ease-in-out;
  transition: 350ms all ease-in-out;
}

.uoe-tab-container .uoe-tab.active {
  background-color: #00a6b6;
}

.uoe-tab-container .uoe-tab:first-of-type {
  margin-top: 0;
}

.uoe-tab-container .uoe-tab:last-of-type {
  margin-bottom: 0;
}

.uoe-tab-container .uoe-tab-content {
  margin-left: 1rem;
  position: relative;
  width: 70%;
}

.uoe-tab-container .uoe-tab-content #uoe-tab-instructions {
  background-color: #e6e6e6;
  padding: 1.5rem 0;
  text-align: center;
}

.uoe-tab-container .uoe-tab-content .uoe-content {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #e6e6e6;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  opacity: 0;
  padding: 0 4rem;
  position: absolute;
  right: -110%;
  top: 0;
  -webkit-transition: right 350ms ease-in-out;
  transition: right 350ms ease-in-out;
  width: 100%;
  z-index: -1;
}

.uoe-tab-container .uoe-tab-content .uoe-content.active {
  opacity: 1;
  right: 0;
  -webkit-transition: right 350ms ease-in-out;
  transition: right 350ms ease-in-out;
  z-index: auto;
}

.uoe-tab-container .uoe-tab-content .uoe-content p {
  color: #00a6b6;
  font-weight: 700;
}
