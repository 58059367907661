#page-footer {
  a {
    color: #fff;

    &:hover {
      color: #fff;
      text-decoration: underline;
    }
  }
}
