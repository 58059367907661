#page-wrapper {
  #page {
    #page-content {
      div[role="main"] {
        blockquote.alternate {
          background: #666;
          border-top: none;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
          color: #fff;

          .fa {
            color: #ffffff;
          }

          .quoted {
            border-bottom: none;
            color: #fff;

            a {
              color: #5f99ca;
              text-decoration: none;
              transition: all 350ms ease-in-out;

              &:hover {
                color: #5f99ca;
                text-decoration: underline;
                transition: all 350ms ease-in-out;
              }
            }
          }
        }
      }
    }
  }
}
