#efpm6-container *,
#efpm6-container h1,
#efpm6-container h2,
#efpm6-container h3,
.efpm6-h4,
.efpm6-h3 #efpm6-container h5,
.efpm6-h4 {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.25;
}

#efpm6-container p {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.45;
}

.efpm6-h4 {
  display: block;
  font-size: 1.5em;
  font-weight: bold;
  padding-bottom: 0px;
}

.efpm6-header-filters *,
.efpm6-header-nav * {
  margin: 0;
  padding: 0;
  transition: 0.4s;
  -webkit-transition: 0.4s;
}

/**-------------------------------------------/ 
 BUTTONS 
/-------------------------------------------*/

#efpm6-container .prime_btn,
#efpm6-container .white_btn {
  color: #c3736b;
}

#efpm6-container .prime_btn {
  background-color: #fffbfb;
  border: 2px solid #c3736b;
  padding: 4px 7px;
  font-size: 19px;
  cursor: pointer;
}

#efpm6-container article .prime_btn {
  background-color: #fffbfb;
  border: 2px solid #c3736b;
  padding: 4px 7px;
  font-size: 14px;
  cursor: pointer;
}

#efpm6-container .white_btn {
  border: 2px solid #c3736b;
  background-color: #fff;
  padding: 4px 12px;
  font-size: 15px;
  text-decoration: none;
}

#efpm6-container .quickLink {
  font-size: 15px;
  color: #995533;
}

#efpm6-container .btn {
  padding: 4px 10px !important;
  border: 2px solid #c3736b !important;
  color: #c3736b !important;
  background-color: #fff !important;
  margin-right: 5px !important;
  margin-top: 0 !important;
  font-size: 15px !important;
}

/**-----------------------------------------/ 
 HEADER LAYOUT 
/-----------------------------------------*/

.efpm6-header-filters,
.efpm6-header-nav {
  display: flex;
  padding: 10px 20px;
}
.efpm6-header-filters {
  justify-content: space-between;
}
.efpm6-header-filters div,
.efpm6-header-filters p {
  display: flex;
}
.efpm6-header-filters ul {
  position: relative;
  top: 0;
}
.efpm6-header-filters ul li {
  display: inline-block;
  margin: 0 0 0.5rem 0.5rem;
  border-radius: 5px;
}
.efpm6-header-nav ul li {
  /* display: inline-block; */
  display: block;
  margin: 0 0 2rem 0.5rem;
  font-size: 12px;
}

/**-------------------------------------------/ 
 ARTICLES 
/-------------------------------------------*/
/* 
.efpm6-article-title {
	width: 100%;
} */
.efpm6-article-title .efpm6-h2 {
  display: block;
  font-size: 1.5em;
  font-weight: bold;
  padding-bottom: 10px;
}
.efpm6-article-title .efpm6-h3 {
  font-size: 16px;
}
.efpm6-article-title a {
  color: #444;
  text-decoration: none;
}
.efpm6-article-content,
.efpm6-article-title {
  display: block;
  padding: 20px;
}
.efpm6-article-content-block {
  position: relative;
  padding: 10px 20px;
  margin: 0 0 0.5rem 0.5rem;
  background-color: #fffbfb;
}
.efpm6-article-content-block.selected:nth-child(odd) {
  background-color: #c5b6b6;
}
.efpm6-article-content-desc {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}
.efpm6-article-content-desc .efpm6-h4,
.efpm6-article-content-desc p {
  padding-bottom: 12px;
}
.efpm6-article-content-desc.selected {
  display: none;
}
.efpm6-article-content-block-links {
  display: flex;
  justify-content: space-between;
}
.efpm6-article-content-block-links a {
  font-size: 12px;
  color: #394f61;
}
.efpm6-article-content-block-links a:before {
  font-family: "FontAwesome";
  font-weight: 900;
  content: "\f007";
  color: #c3736b;
  margin-right: 5px;
  font-size: 12px;
}
.efpm6-article-content-block-links p {
  font-size: 12px;
  padding: 4px 8px;
}
.efpm6-article-content-block-desc .efpm6-h3 {
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 6px;
}
#efpm6-container .selected {
  background-color: #c5b6b6;
  color: #995533;
  border: 1px solid #995533;
}
#efpm6-container .selected .efpm6-article-content-block-links p {
  background-color: #d6c4c4;
  color: #c5b6b6;
  border: 1px solid #d6c4c4;
}
#efpm6-container .selected .efpm6-article-content-block-links a {
  color: #d6c4c4;
}
#efpm6-container .selected .efpm6-article-content-block-links a:before {
  content: "\f007";
  color: #d6c4c4;
}
.efpm6-article-content-block-links a {
  position: relative;
  top: 5px;
}

/**-------------------------------------------/ 
 CHECKBOX 
/-------------------------------------------*/

.efpm6-switch-cont .efpm6-h4 {
  position: relative;
  top: -5px;
}
.efpm6-switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 24px;
  margin-left: 5px;
}
.efpm6-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c5b6b6;
  color: #fffbfb;
  font-size: 9px;
  border-radius: 24px;
  padding: 5px 7px;
  border: 1px solid #c3736b;
  transition: 0.4s;
  -webkit-transition: 0.4s;
}
.efpm6-slider p {
  float: right;
}
.efpm6-slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 3px;
  border-radius: 50%;
  background-color: #c3736b;
  transform: translateX(0);
  transition: 0.4s;
  -webkit-transition: 0.4s;
}
.efpm6-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.efpm6-switch input:focus + span {
  border: 1px solid #4d90fe;
}
.efpm6-switch input.selected + .efpm6-slider {
  color: #d6c4c4;
  background-color: #fffbfb;
}
.efpm6-switch input.selected + .efpm6-slider p {
  color: #c3736b;
  float: left;
}
.efpm6-switch input.selected + .efpm6-slider:before {
  transform: translateX(30px);
}

/**-----------------------------------------/ 
 MEDIA QUERIES 
/-----------------------------------------*/

@media only screen and (min-width: 640px) {
  #efpm6-container article {
    display: flex;
  }
  .efpm6-article-title {
    width: 215px;
  }
  .efpm6-article-content {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
  }
  .efpm6-article-content-block {
    display: inline-block;
    min-width: 200px;
    max-width: 200px;
    min-height: 235px;
  }

  .efpm6-article-content-block-links {
    position: absolute;
    width: 85%;
    bottom: 10px;
  }
  .efpm6-header-filters ul {
    position: relative;
    top: -3px;
  }
}
@media only screen and (min-width: 800px) {
  .efpm6-header-nav ul li {
    display: inline-block;
    margin: 0 0 0.5rem 0.5rem;
  }
}
#efpm6-container article:nth-child(even) {
  background-color: #faf4f4;
}
.efpm6-article-title a {
  color: #a37777;
}
#efpm6-container #header {
  background-color: #d6c4c4;
}
