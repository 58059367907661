.book-suggestion-container {
  .book-suggestion-btn {
    margin-bottom: 0px !important;
  }
  .book-suggestion-instruction {
    color: #808080;
  }
  .book-suggestion-content-container {
    display: none;
    margin-top: 10px;
    padding: 20px;
    position: relative;
    border: 1px solid #808080;

    .book-suggestion-image-container {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.1;
    }

    ul {
      list-style-type: none;
    }
  }
}
