&#page-mod-book-view {
  //apply to Book pages only

  .navimages {
    //apply to "images" style navigation only

    i.icon.fa {
      font-size: 2em;
      height: auto;
      line-height: 1;
    }

    &.navtop {
      margin-bottom: 0;
    }
  }
}
