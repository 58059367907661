.kp-interactive.timeline-entepreneurship {
  & > .dragDrop {
    .dragDropRetry,
    .dragDropSubmit,
    .dragDropSummary {
      display: none;
    }
    .dragDropSummary {
      display: none;
      background: $exeter-kp-light-grey;
      border-radius: 10px;
      border: 2px dashed $exeter-bus-grey;
      padding: 2em;
      h5,
      h6 {
        font-family: "Open Sans", sans-serif !important;
      }
      h5 {
        font-size: 1em;
        color: $exeter-navy;
        margin-left: 1.5em;
        &:before {
          font-family: "FontAwesome";
          content: "\f017";
          position: absolute;
          margin-left: -1.5em;
        }
      }
      h6 {
        font-size: 0.85em;
        margin-left: 2em;
      }
      ul {
        li {
          list-style-type: disc !important;
          &:before {
            display: none;
          }
        }
      }
    }
    .dragContainer {
      .dragObject {
        width: 60px;
        height: 60px;
        position: relative;
        text-align: center;
        border-radius: 50% !important;
      }
    }
    .dropContainer {
      margin: 0;
      justify-content: center !important;
      position: relative;
      .startTimeline,
      .endTimeline {
        position: absolute;
        margin: 1em 0;
        h5 {
          color: #fff;
          padding: 15px 10px;
          width: 90px;
          background: $exeter-blue;
          margin: 0 0 0 -10px !important;
          font-size: 1em;
          text-align: center;
          font-family: "Open Sans", sans-serif !important;
        }
        &:after {
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          top: 100%;
          left: 50%;
          border: solid transparent;
          border-top-color: $exeter-blue;
          border-width: 10px;
          margin-left: -10px;
        }
      }
      .endTimeline {
        right: 0;
        @media screen and (-ms-high-contrast: active),
          screen and (-ms-high-contrast: none),
          all and (max-width: 900px) {
          top: 0;
        }
      }
      .startTimeline {
        left: 0;
        @media screen and (-ms-high-contrast: active),
          screen and (-ms-high-contrast: none),
          all and (max-width: 900px) {
          top: 0;
        }
      }
      .timeline {
        margin: 8em 1em;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
          display: block;
        }
        .dropWrapper {
          border-right: 1px dotted black;
          text-align: center;
          background: #f2f2f2;
          height: 60px !important;
          width: 85px;
          position: relative;
          display: inline-block;
          margin: 0 !important;
          @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
            width: 75px !important;
            margin-right: -3px !important;
            &:first-of-type {
              margin-left: -20px !important;
            }
            &:last-of-type {
              top: -15px;
            }
          }
          @media screen and (-ms-high-contrast: active),
            screen and (-ms-high-contrast: none),
            all and (max-width: 900px) {
            margin-top: 10px !important;
            &:last-of-type {
              top: -16px;
            }
          }
          h5 {
            font-size: 1em;
            margin: 0.95em 0 0 0 !important;
            font-family: "Open Sans", sans-serif !important;
          }
          &:last-of-type {
            border-right: none !important;
          }
          .dropObject {
            height: 100% !important;
            width: 100%;
            border: none !important;
            margin: 0 auto !important;
            position: absolute !important;
            top: 0;
            h5 {
              margin-top: 1em;
            }
            .droppedObject {
              padding-top: 0 !important;
              h4 {
                padding-top: 7px;
              }
            }
            &.highlight {
              background: rgba($exeter-kp-grey, 0.5);
            }
          }
        }
      }
    }
  }
}
