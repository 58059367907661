#page-wrapper {
  #page {
    #page-content {
      div[role="main"] {
        .exeter-table-special .exeter-table {
          width: 100%;
          border-collapse: collapse;
          color: #666;

          th {
            background: #004c9c;
            border: 1px solid #004c9c;
            color: #fff;
            font-size: 18px;
            padding: 10px;
            text-align: center;
          }

          td {
            padding: 8px;
            border: 1px solid #ddd;
          }

          tr:nth-child(odd) {
            background: #f4f3f2;
          }
        }
      }
    }
  }
}
