.kp-interactive {
  &.assessment-carousel {
    .swiper-container {
      font-size: 0.95em;
      width: 100%;
      color: #fff;
      -webkit-box-shadow: none;
      box-shadow: none;
      letter-spacing: normal;
      height: 300px;

      &:after {
        display: none;
      }

      .swiper-slide {
        background-size: cover !important;
        background-position: center center !important;
        position: relative;

        &:after {
          display: block;
          content: "";
          width: 100%;
          height: 100%;
          -webkit-transition: all 0.6s ease-in;
          transition: all 0.6s ease-in;
          background: rgba(33, 33, 33, 0.3);
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }

        &.swiper-slide-active {
          &:after {
            background: rgba(33, 33, 33, 0.7);
          }

          .text-container {
            opacity: 1;
          }
        }

        .text-container {
          -webkit-transition: all 0.6s ease-in;
          transition: all 0.6s ease-in;
          opacity: 0;
          z-index: 2;
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 15px;

          .slide-title {
            font-weight: bold;
          }

          .right {
            text-align: right;
          }

          .assessment-link {
            border: solid 1px #fff;
            padding: 5px 10px;
            border-radius: 3px;
            color: #fff !important;
            -webkit-transition: all 0.3s ease-in;
            transition: all 0.3s ease-in;

            &:hover {
              text-decoration: none !important;
              background: rgba(255, 255, 255, 0.15);
            }
          }
        }
      }
      @media screen and (max-width: 768px) {
        .swiper-slide {
          .text-container {
            padding: 15px 40px;
          }
        }

        .swiper-button-next,
        .swiper-button-prev {
          background-size: 20px 20px;
        }
      }
    }
  }
}
