.kp-interactive {
  &.uegp-rs {
    //uegp
    .activity-header {
      background: url("//keypath.exeter.ac.uk/img/courses/uegp/unit11/books.jpg");
      padding: 90px 0;
      background-size: cover;

      input {
        width: 90%;
        margin: auto;
        padding: 10px;
        font-size: 30px;
        color: #fff;
        display: block;
        border: 3px solid #fff;
        background: none;
      }

      :-moz-placeholder,
      :-ms-input-placeholder,
      ::-moz-placeholder,
      ::-webkit-input-placeholder {
        color: #ffffff !important;
      }
    }

    .slidebar {
      margin: 20px 0;
      background: #2cabd8;
      /* Old browsers */
      background: -moz-linear-gradient(left, #2cabd8 0%, #0000a1 100%);
      /* FF3.6-15 */
      background: -webkit-linear-gradient(left, #2cabd8 0%, #0000a1 100%);
      /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, #2cabd8 0%, #0000a1 100%);
      /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }

    .ui-state-default {
      background: #fff !important;
      border-radius: 50%;
      outline: none;
      box-shadow: 0 0 10px #29a1d5;
    }

    .ui-state-default:focus {
      background: #eee;
    }

    .slider-title {
      font-size: 1.25em;
      font-weight: bold;
      margin-top: 10px;
    }

    .level {
      padding: 0;
      color: #fff;
      line-height: 1.5;

      img {
        margin: 0;
        width: 100%;
        max-width: 100% !important;
      }

      .level-title {
        font-size: 1.5em;
        padding: 15px;
        text-align: center;
        font-weight: bold;
      }

      .level-info {
        font-size: 1em;
        padding: 15px;
        text-align: center;
        display: none;
      }

      &.level-1 {
        background: #2cabd8;
      }

      &.level-2 {
        background: #1f6ed4;
      }

      &.level-3 {
        background: #0000a1;
      }
    }

    .save-btn-position {
      text-align: right;

      .save-btn {
        background: #004c9c;
        color: #fff;
        padding: 12px 20px;
        border: none;
        font-weight: bold;
      }
    }
  }
}
