#page-wrapper {
  #page {
    #page-content {
      div[role="main"] {
        .kp-ex-hero {
          background-repeat: no-repeat;
          background-size: cover;
          overflow-y: hidden;

          .ex-content {
            color: #fff;
            height: 100%;
            padding: 2rem;
            max-width: 38%;

            @media only screen and (max-width: 600px) {
              max-width: 75%;
            }

            h1,
            h2 {
              background: rgba(0, 0, 0, 0);
              font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
              padding: 0;

              &:before,
              &:after {
                content: none;
              }
            }

            h3 {
              border-bottom: none;
            }

            p {
              line-height: 1.75;

              &:last-of-type {
                margin-bottom: 0;
              }
            }
            a {
              color: #5d8dff !important;
            }

            &.left {
              margin-right: auto;
            }

            &.right {
              margin-left: auto;
            }

            &.navy {
              background-color: rgba(22, 36, 61, 0.8);
            }

            &.gray {
              background-color: rgba(75, 78, 81, 0.9);
            }

            .title {
              border-bottom: none;
              color: #fff;
              margin-top: 0;
            }
          }
        }

        .kp-ex-media-content {
          align-items: center;
          display: flex;
          justify-content: center;
          position: relative;

          &.top {
            align-items: flex-start;
          }

          &.bottom {
            align-items: flex-end;
          }

          &.navy {
            .ex-content {
              background-color: transparentize(#16243d, 0.2);
              height: 100%;

              p {
                color: #fff;
              }
            }
          }

          &.half-half {
            @media only screen and (max-width: 600px) {
              flex-wrap: wrap;
            }

            & > * {
              flex-basis: 50%;

              @media only screen and (max-width: 600px) {
                width: 100%;
              }
            }

            .kp-media {
              width: 50%;

              img {
                min-width: 100%;
                width: 100%;
              }
            }
          }

          // &.grey{
          //     background-color: ;
          // }
          //
          // &.light-grey{
          //     background-color: ;
          // }

          @media only screen and (max-width: 600px) {
            flex-direction: column;
          }

          .kp-media {
            flex-basis: 33%;

            &.half-width {
              flex-basis: 50%;

              @media only screen and (max-width: 600px) {
                max-width: 100%;
              }
            }

            @media only screen and (max-width: 600px) {
              max-width: 100%;
              width: 100%;
            }

            img {
              width: 100%;
            }
          }

          // STYLES FOR RESPONSIVE YOUTUBE VIDEOS
          .video-container {
            padding-bottom: 56.25%;
            padding-top: 30px;
            height: 0;
            overflow: hidden;
            position: relative;

            iframe,
            object,
            embed {
              left: 0;
              height: 100%;
              position: absolute;
              top: 0;
              width: 100%;
            }
          }
          // END YOUTUBE STYLES

          .ex-content {
            flex-basis: 66%;
            padding: 2rem;

            h1,
            h2 {
              background: rgba(0, 0, 0, 0);
              font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
              padding: 0;

              &:before,
              &:after {
                content: none;
              }
            }

            h3 {
              border-bottom: none;
            }

            &.no-padding {
              padding: 0 2rem;
            }

            p {
              line-height: 1.7;

              &:last-of-type {
                margin-bottom: 0;
              }
            }
          }
        }

        .kp-callout-18 {
          align-items: center;
          background: #f9f9f9;
          border: 7px solid #555;
          border-radius: 5px;
          box-shadow: 0 0 10px 0 rgba(150, 150, 150, 0.5), inset 0 0 5px 0 rgba(150, 150, 150, 0.5);
          display: flex;
          margin: 1rem auto;
          padding: 2em 1em;
          position: relative;
          width: 100%;

          @media only screen and (max-width: 850px) {
            align-items: flex-start;
            width: 95%;
          }

          &.download {
            border-color: #6666bb;

            .fa {
              color: #6666bb;
            }

            .callout-content {
              .title {
                color: #6666bb;
              }
            }
          }

          h1,
          h2,
          h3 {
            background: rgba(0, 0, 0, 0);
            font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
            padding: 0;

            &:before,
            &:after {
              content: none;
            }
          }

          h3 {
            border-bottom: none;
            margin-bottom: 10px !important;
            padding: 5px 0 0;
          }

          &.info {
            border-color: #aa1da8;

            .fa {
              color: #aa1da8;
            }

            .callout-content {
              .title {
                color: #aa1da8;
              }
            }
          }

          &.tech-tutorial {
            border-color: #327b73;

            .fa {
              color: #327b73;
            }

            .callout-content {
              .title {
                color: #327b73;
              }
            }
          }

          &.reading {
            border-color: #3876a8;

            .fa {
              color: #3876a8;
            }

            .callout-content {
              .title {
                color: #3876a8;
              }
            }
          }

          &.think-about {
            border-color: #397f3e;

            .fa {
              color: #397f3e;
            }

            .callout-content {
              .title {
                color: #397f3e;
              }
            }
          }

          &.objective {
            border-color: #375251;

            .fa {
              color: #375251;
            }

            .callout-content {
              .title {
                color: #375251;
              }
            }
          }

          &.reminder {
            border-color: #b800eb;

            .fa {
              color: #b800eb;
            }

            .callout-content {
              .title {
                color: #b800eb;
              }
            }
          }

          &.outside-resource {
            border-color: #004c9c;

            .fa {
              color: #004c9c;
            }

            .callout-content {
              .title {
                color: #004c9c;
              }
            }
          }

          &.end-of-week {
            border-color: #00beeb;

            .fa {
              color: #00beeb;
            }

            .callout-content {
              .title {
                color: #00beeb;
              }
            }
          }

          .fa {
            font-size: 4rem;
            margin-left: 1rem;
            margin-right: 2rem;

            @media only screen and (max-width: 500px) {
              font-size: 24px;
              left: 5px;
              position: absolute;
              top: 33px;
            }
          }

          .callout-content {
            border-radius: 5px;

            h1,
            h2 {
              background: rgba(0, 0, 0, 0);
              font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
              padding: 0;

              &:before,
              &:after {
                content: none;
              }
            }

            h3 {
              border-bottom: none;
            }

            .title {
              font-weight: 700;
              margin-top: 0;
              text-shadow: 1px 1px rgba(100, 100, 100, 0.2);

              @media only screen and (max-width: 500px) {
                margin-left: 35px;
              }
            }

            p {
              font-size: calc(16px + 6 * ((100vw - 320px) / 680));
              margin-right: 0.5rem;

              @media screen and (min-width: 800px) {
                font-size: 16px;
              }

              &:last-of-type {
                margin-bottom: 0;
              }
            }
            .break-word {
              word-break: break-word;
              color: #004c9c !important;
            }
          }
        }
      }
    }
  }
}
