&:not(.editing) {
  #activity-tabs {
    display: none;
  }
}

.activitiestabsetup {
  width: 100%;

  .activitiestablist {
    display: flex;

    .activitiestab {
      flex: 1;
      background-color: #004c9c;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-right: 1px solid #fff;
      min-height: 125px;
      margin-top: 25px;
      position: relative;
      border-radius: 0;
      cursor: pointer;
      overflow: hidden;
      transition: margin-top 0.3s, min-height 0.3s, border-radius 0.3s;

      &:first-child {
        border-radius: 1em 0 0 0;
      }

      &:last-child {
        border-right: none;
        border-radius: 0 1em 0 0;
      }

      .activitiestaboverlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        padding: 18px 24px;
        font-family: Georgia, serif;
        font-size: 1.40625rem;
        line-height: normal;
        background-color: rgba(102, 102, 102, 0.7);
        color: #fff;
        transition: background-color 0.3s, color 0.3s;
      }

      &:hover {
        .activitiestaboverlay {
          background-color: rgba(0, 76, 156, 0.7);
        }
      }

      &[aria-selected="true"] {
        min-height: 150px;
        margin-top: 0;
        border-radius: 1em 1em 0 0 !important;
        cursor: default;

        .activitiestaboverlay {
          background-color: rgba(255, 255, 255, 0.85);
          color: #004c9c;
          height: auto;
        }
      }
    }
  }

  .activitiescontent {
    border: 1px solid #ddd;
    border-radius: 0 0 4px 4px;
    border-top: 2px solid #004c9c;
    padding: 24px;

    .activitiespanel {
      display: none;

      & > ul {
        list-style-type: none;
        margin: 0 !important;
        padding: 0;

        li {
          &::before {
            display: none !important;
          }
        }
      }
    }
  }
}
