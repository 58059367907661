#page-wrapper {
  #page {
    #page-content {
      div[role="main"] {
        .stand-out-section {
          padding: 15px 20px;
          background-color: rgba(249, 209, 209, 0.38);
          font-size: 1.2rem;
          margin-left: 15px;
          margin-right: 15px;
          color: #1f2021;
          h3 {
            border-bottom: none;
          }
          dl {
            dl {
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
}
