// induction with tiles
&.pagelayout-course {
  // copyable code starts here
  &.course-7172:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/induction/tutor/welcome.jpg");
          }
        }
        &#section-2 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/induction/tutor/unit1.jpg");
          }
        }
        &#section-3 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/induction/tutor/unit2.jpg");
          }
        }
        &#section-4 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/induction/tutor/unit3.jpg");
          }
        }
        &#section-5 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/induction/tutor/unit4.jpg");
          }
        }
        &#section-6 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/induction/tutor/unit5.jpg");
          }
        }
        &#section-7 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/induction/tutor/unit6.jpg");
          }
        }
        &#section-8 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/induction/tutor/other.jpg");
          }
        }
      }
    }
  }
  // paste copied code below this line
  &.course-7205:not(.editing),
  &.course-7527:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm128/week0.jpg");
          }
        }
        &#section-2 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm128/week1.jpg");
          }
        }
        &#section-3 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm128/week2.jpg");
          }
        }
        &#section-4 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm128/week3.jpg");
          }
        }
        &#section-5 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm128/week4.jpg");
          }
        }
        &#section-6 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm128/week5.jpg");
          }
        }
        &#section-7 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm128/week6.jpg");
          }
        }
        &#section-8 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm128/week7.jpg");
          }
        }
        &#section-9 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm128/week8.jpg");
          }
        }
      }
    }
  }
  &.course-7206:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm108/thumbwelcome.jpg");
          }
        }
        &#section-2 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm108/thumbweek1.jpg");
          }
        }
        &#section-3 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm108/thumbweek2.jpg");
          }
        }
        &#section-4 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm108/thumbweek3.jpg");
          }
        }
        &#section-5 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm108/thumbweek4.jpg");
          }
        }
        &#section-6 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm108/thumbweek5.jpg");
          }
        }
        &#section-7 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm108/thumbweek6.jpg");
          }
        }
        &#section-8 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm108/thumbweek7.jpg");
          }
        }
      }
    }
  }
  // &.course-7510:not(.editing) {
  //   .topics {
  //     li.section:not(#section-0) {
  //       &#section-1 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week0.png');
  //         }
  //       }
  //       &#section-2 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week13.png');
  //         }
  //       }
  //       &#section-3 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week1.png');
  //         }
  //       }
  //       &#section-4 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week3.png');
  //         }
  //       }
  //       &#section-5 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week4.png');
  //         }
  //       }
  //       &#section-6 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week5.png');
  //         }
  //       }
  //       &#section-7 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week6.png');
  //         }
  //       }
  //       &#section-8 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week7.png');
  //         }
  //       }
  //       &#section-9 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week8.png');
  //         }
  //       }
  //       &#section-10 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week9.png');
  //         }
  //       }
  //       &#section-11 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week10.png');
  //         }
  //       }
  //       &#section-12 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week11.png');
  //         }
  //       }
  //       &#section-13 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week12.png');
  //         }
  //       }
  //       &#section-14 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week13.png');
  //         }
  //       }
  //       &#section-15 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week7.png');
  //         }
  //       }
  //     }
  //   }
  // }
  // &.course-7721:not(.editing) {
  //   .topics {
  //     li.section:not(#section-0) {
  //       &#section-1 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week0.png');
  //         }
  //       }
  //       &#section-2 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week13.png');
  //         }
  //       }
  //       &#section-3 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week1.png');
  //         }
  //       }
  //       &#section-4 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week3.png');
  //         }
  //       }
  //       &#section-5 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week4.png');
  //         }
  //       }
  //       &#section-6 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week5.png');
  //         }
  //       }
  //       &#section-7 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week6.png');
  //         }
  //       }
  //       &#section-8 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week7.png');
  //         }
  //       }
  //       &#section-9 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week8.png');
  //         }
  //       }
  //       &#section-10 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week9.png');
  //         }
  //       }
  //       &#section-11 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week10.png');
  //         }
  //       }
  //       &#section-12 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week11.png');
  //         }
  //       }
  //       &#section-13 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week12.png');
  //         }
  //       }
  //       &#section-14 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week13.png');
  //         }
  //       }
  //       &#section-15 {
  //         h3 a:before {
  //           background: url('https://keypath.exeter.ac.uk/courses/EFPM007/general/week7.png');
  //         }
  //       }
  //     }
  //   }
  // }
  &.course-7511:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/welcome.jpg");
          }
        }
        &#section-2 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%201.jpg");
          }
        }
        &#section-3 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%202.jpg");
          }
        }
        &#section-4 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%203.jpg");
          }
        }
        &#section-5 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%204.jpg");
          }
        }
        &#section-6 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%205.jpg");
          }
        }
        &#section-7 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%206.jpg");
          }
        }
        &#section-8 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%207.jpg");
          }
        }
        &#section-9 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%208.jpg");
          }
        }
        &#section-10 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%209.jpg");
          }
        }
        &#section-11 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%2010.jpg");
          }
        }
        &#section-12 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%2011.jpg");
          }
        }
        &#section-13 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%2012.jpg");
          }
        }
        &#section-14 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%2013.jpg");
          }
        }
        &#section-15 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%2014.jpg");
          }
        }
      }
    }
  }
  &.course-7410:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/bemm175/welcome.png");
          }
        }
        &#section-2 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/bemm175/week1.jpg");
          }
        }
        &#section-3 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/bemm175/week2.png");
          }
        }
        &#section-4 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/bemm175/week3.png");
          }
        }
        &#section-5 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/bemm175/week4.png");
          }
        }
        &#section-6 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/bemm175/week5.jpg");
          }
        }
        &#section-7 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/bemm175/week6.png");
          }
        }
        &#section-8 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/bemm175/week7.jpg");
          }
        }
      }
    }
  }
  &.course-7726:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/bemm175/welcome.png");
          }
        }
        &#section-2 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/bemm175/week1.jpg");
          }
        }
        &#section-3 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/bemm175/week2.png");
          }
        }
        &#section-4 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/bemm175/week3.png");
          }
        }
        &#section-5 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/bemm175/week4.png");
          }
        }
        &#section-6 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/bemm175/week5.jpg");
          }
        }
        &#section-7 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/bemm175/week6.png");
          }
        }
        &#section-8 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/bemm175/week7.jpg");
          }
        }
      }
    }
  }
  &.course-7722:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/welcome.jpg");
          }
        }
        &#section-2 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%201.jpg");
          }
        }
        &#section-3 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%202.jpg");
          }
        }
        &#section-4 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%203.jpg");
          }
        }
        &#section-5 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%204.jpg");
          }
        }
        &#section-6 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%205.jpg");
          }
        }
        &#section-7 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%206.jpg");
          }
        }
        &#section-8 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%207.jpg");
          }
        }
        &#section-9 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%208.jpg");
          }
        }
        &#section-10 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%209.jpg");
          }
        }
        &#section-11 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%2010.jpg");
          }
        }
        &#section-12 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%2011.jpg");
          }
        }
        &#section-13 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%2012.jpg");
          }
        }
        &#section-14 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%2013.jpg");
          }
        }
        &#section-15 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/EFPM011/general/img/week%2014.jpg");
          }
        }
      }
    }
  }
  &.course-7513:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/bemm129/general/week%200.png");
          }
        }
        &#section-2 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/bemm129/general/week%201.png");
          }
        }
        &#section-3 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/bemm129/general/week%202.png");
          }
        }
        &#section-4 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/bemm129/general/week%203.png");
          }
        }
        &#section-5 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/bemm129/general/week%204.png");
          }
        }
        &#section-6 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/bemm129/general/week%205.png");
          }
        }
        &#section-7 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/bemm129/general/week%206.png");
          }
        }
        &#section-8 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/bemm129/general/week%207.png");
          }
        }
      }
    }
  }
  &.course-7718:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/bemm129/general/week%200.png");
          }
        }
        &#section-2 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/bemm129/general/week%201.png");
          }
        }
        &#section-3 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/bemm129/general/week%202.png");
          }
        }
        &#section-4 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/bemm129/general/week%203.png");
          }
        }
        &#section-5 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/bemm129/general/week%204.png");
          }
        }
        &#section-6 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/bemm129/general/week%205.png");
          }
        }
        &#section-7 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/bemm129/general/week%206.png");
          }
        }
        &#section-8 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/courses/bemm129/general/week%207.png");
          }
        }
      }
    }
  }
  // &.course-6736:not(.editing),
  &.course-7580:not(.editing),
  &.course-7581:not(.editing),
  &.course-7582:not(.editing),
  &.course-7583:not(.editing),
  &.course-7210:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-welcome.jpg");
          }
        }
        &#section-2 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week1.jpg");
          }
        }
        &#section-3 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week2.jpg");
          }
        }
        &#section-4 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week3.jpg");
          }
        }
        &#section-5 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week4.jpg");
          }
        }
        &#section-6 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week5.jpg");
          }
        }
        &#section-7 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week6.jpg");
          }
        }
        &#section-8 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week7.jpg");
          }
        }
        &#section-9 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week8.jpg");
          }
        }
        &#section-10 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week9.jpg");
          }
        }
        &#section-11 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week10.jpg");
          }
        }
        &#section-12 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week11.jpg");
          }
        }
        &#section-13 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week12.jpg");
          }
        }
        &#section-14 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week13.jpg");
          }
        }
        &#section-15 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week14.jpg");
          }
        }
      }
    }
  }
  &.course-7720:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-welcome.jpg");
          }
        }
        &#section-2 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week1.jpg");
          }
        }
        &#section-3 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week2.jpg");
          }
        }
        &#section-4 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week3.jpg");
          }
        }
        &#section-5 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week4.jpg");
          }
        }
        &#section-6 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week5.jpg");
          }
        }
        &#section-7 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week6.jpg");
          }
        }
        &#section-8 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week7.jpg");
          }
        }
        &#section-9 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week8.jpg");
          }
        }
        &#section-10 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week9.jpg");
          }
        }
        &#section-11 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week10.jpg");
          }
        }
        &#section-12 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week11.jpg");
          }
        }
        &#section-13 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week12.jpg");
          }
        }
        &#section-14 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week13.jpg");
          }
        }
        &#section-15 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week14.jpg");
          }
        }
      }
    }
  }
  &.course-7748:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-welcome.jpg");
          }
        }
        &#section-2 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week1.jpg");
          }
        }
        &#section-3 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week2.jpg");
          }
        }
        &#section-4 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week3.jpg");
          }
        }
        &#section-5 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week4.jpg");
          }
        }
        &#section-6 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week5.jpg");
          }
        }
        &#section-7 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week6.jpg");
          }
        }
        &#section-8 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week7.jpg");
          }
        }
        &#section-9 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week8.jpg");
          }
        }
        &#section-10 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week9.jpg");
          }
        }
        &#section-11 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week10.jpg");
          }
        }
        &#section-12 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week11.jpg");
          }
        }
        &#section-13 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week12.jpg");
          }
        }
        &#section-14 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week13.jpg");
          }
        }
        &#section-15 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week14.jpg");
          }
        }
      }
    }
  }
  &.course-7749:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/uegp/uegp-welcome.jpg");
          }
        }
        &#section-2 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week1.jpg");
          }
        }
        &#section-3 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week2.jpg");
          }
        }
        &#section-4 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week3.jpg");
          }
        }
        &#section-5 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week4.jpg");
          }
        }
        &#section-6 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week5.jpg");
          }
        }
        &#section-7 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week6.jpg");
          }
        }
        &#section-8 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week7.jpg");
          }
        }
        &#section-9 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week8.jpg");
          }
        }
        &#section-10 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week9.jpg");
          }
        }
        &#section-11 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week10.jpg");
          }
        }
        &#section-12 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week11.jpg");
          }
        }
        &#section-13 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week12.jpg");
          }
        }
        &#section-14 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week13.jpg");
          }
        }
        &#section-15 {
          h3 a:before {
            background: url("https://keypath.exeter.ac.uk/img/courses/ulgp/heroes/week14.jpg");
          }
        }
      }
    }
  }
  &.course-7697:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm108/thumbwelcome.jpg");
          }
        }
        &#section-2 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm108/thumbweek1.jpg");
          }
        }
        &#section-3 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm108/thumbweek2.jpg");
          }
        }
        &#section-4 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm108/thumbweek3.jpg");
          }
        }
        &#section-5 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm108/thumbweek4.jpg");
          }
        }
        &#section-6 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm108/thumbweek5.jpg");
          }
        }
        &#section-7 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm108/thumbweek6.jpg");
          }
        }
        &#section-8 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm108/thumbweek7.jpg");
          }
        }
      }
    }
  }
  &.course-7291:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm374/week0.jpg");
          }
        }
        &#section-2 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm374/week1.jpg");
          }
        }
        &#section-3 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm374/week2.jpg");
          }
        }
        &#section-4 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm374/week3.jpg");
          }
        }
        &#section-5 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm374/week4.jpg");
          }
        }
        &#section-6 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm374/week5.jpg");
          }
        }
        &#section-7 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm374/week6.jpg");
          }
        }
        &#section-8 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm374/week7.jpg");
          }
        }
      }
    }
  }
  &.course-7719:not(.editing) {
    .topics {
      li.section:not(#section-0) {
        &#section-1 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm374/week0.jpg");
          }
        }
        &#section-2 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm374/week1.jpg");
          }
        }
        &#section-3 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm374/week2.jpg");
          }
        }
        &#section-4 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm374/week3.jpg");
          }
        }
        &#section-5 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm374/week4.jpg");
          }
        }
        &#section-6 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm374/week5.jpg");
          }
        }
        &#section-7 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm374/week6.jpg");
          }
        }
        &#section-8 {
          h3 a:before {
            background: url("http://keypath.exeter.ac.uk/img/courses/bemm374/week7.jpg");
          }
        }
      }
    }
  }
}
