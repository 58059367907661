/*
* 	Default theme - Owl Carousel CSS File
*/

$owl-color-base: #869791 !default;
$owl-color-white: #fff !default;
$owl-color-gray: #d6d6d6 !default;

//nav

$owl-nav-color: $owl-color-white !default;
$owl-nav-color-hover: $owl-color-white !default;
$owl-nav-font-size: 14px !default;
$owl-nav-rounded: 3px !default;
$owl-nav-margin: 5px !default;
$owl-nav-padding: 4px 7px !default;
$owl-nav-background: $owl-color-gray !default;
$owl-nav-background-hover: $owl-color-base !default;
$owl-nav-disabled-opacity: 0.5 !default;

//dots

$owl-dot-width: 10px !default;
$owl-dot-height: 10px !default;
$owl-dot-rounded: 30px !default;
$owl-dot-margin: 5px 7px !default;
$owl-dot-background: $owl-color-gray !default;
$owl-dot-background-active: $owl-color-base !default;

.loading {
  text-align: center;
}
/*
 *  Owl Carousel - Core
 */

.owl-carousel {
  display: none;
  margin: 20px 0;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;

  .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden; /* fix firefox animation glitch */
  }

  .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }

  .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px);
    transform: translate3d(0px, 0px, 0px);
  }

  .owl-wrapper,
  .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    z-index: 101;
    padding: 0 26px;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;

    .presentation-comment {
      div[id^="embedded-feed"] {
        height: 100%;
      }
    }

    .ex-content.presentation {
      min-height: 220px;

      @media (max-width: 615px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
      }
    }

    iframe {
      width: 100%;
      margin: auto;

      @media (max-width: 615px) {
        display: none;
      }
    }

    .mobile-ppt {
      display: none;
      background: #f9e9df;
      font-size: 16px;
      text-align: center;
      padding: 6px;
      margin: 6px;
      border: 1px solid #ecc493;
      border-radius: 4px;

      @media (max-width: 615px) {
        display: block;
      }
    }

    .owl-title {
      width: 100%;
      position: absolute;

      h4 {
        font-size: 1.5rem;
        color: $exeter-blue;
        font-family: "Open Sans";
        margin: 0;
        padding: 9px 12px;
      }

      h2,
      h3 {
        font-family: "Open Sans", sans-serif !important;
        font-weight: normal;
        text-align: center;
        width: 50% !important;
        left: 50%;
        margin: 0 0 0 -25% !important;
        border-radius: 0 0 10px 10px;
        background-blend-mode: multiply;
        background: rgba($exeter-bus-grey, 0.9) !important;
        box-shadow: 0 0 1px 1px #000;
        &:before,
        &:after {
          display: none !important;
        }
      }
    }

    .owl-caption {
      position: absolute;
      z-index: 3;
      bottom: 0;
      width: 80%;
      left: 50%;
      margin-left: -40%;
      background: rgba($exeter-bus-grey, 0.9);
      color: #fff;
      padding: 10px 10px 15px 20px;
      line-height: 1.6em;
      border-radius: 15px 16px 0 0;
      box-shadow: -1px -1px 1px 0 #000;
      p,
      ul {
        margin-bottom: 1.5em;
      }
      ul {
        li {
          list-style-type: disc !important;
          &:before {
            display: none;
          }
        }
      }
    }

    img {
      display: block;
      width: 100%;
    }
  }

  .owl-nav.disabled,
  .owl-dots.disabled {
    display: none;
  }

  .owl-nav .owl-prev,
  .owl-nav .owl-next,
  .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .owl-nav button.owl-prev,
  .owl-nav button.owl-next,
  button.owl-dot {
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
  }

  &.owl-loaded {
    display: block;
  }

  &.owl-loading {
    opacity: 0;
    display: block;
  }

  &.owl-hidden {
    opacity: 0;
  }

  &.owl-refresh .owl-item {
    visibility: hidden;
  }

  &.owl-drag .owl-item {
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &.owl-grab {
    cursor: move;
    cursor: grab;
  }

  &.owl-rtl {
    direction: rtl;
  }

  &.owl-rtl .owl-item {
    float: right;
  }

  // Keypath Styling for iFrame PowerPoint and Yammer Combo
  // ask Dan for deets
  .ex-content.comments {
    width: auto;
    height: 100%;
    background: #fff;
    border: 1px solid #cfcfcf;
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;

    @media (max-width: 615px) {
      width: 100%;
    }
  }

  & + .comments-bar {
    text-align: right;
    padding: 0 26px;

    button {
      text-align: center;
    }
    .hide-comment {
      display: none;
    }

    @media (max-width: 615px) {
      display: none;
    }
  }
}

/* No Js */
&.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel {
  .animated {
    animation-duration: 1000ms;
    animation-fill-mode: both;
  }
  .owl-animated-in {
    z-index: 0;
  }
  .owl-animated-out {
    z-index: 1;
  }
  .fadeOut {
    animation-name: fadeOut;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */

.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */

.owl-carousel {
  .owl-item {
    .owl-lazy {
      opacity: 0;
      transition: opacity 400ms ease;
    }

    /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */
    .owl-lazy[src^=""],
    .owl-lazy:not([src]) {
      max-height: 0;
    }

    img.owl-lazy {
      transform-style: preserve-3d;
    }
  }
}

$owl-image-path: "" !default;

/*
 * 	Owl Carousel - Video Plugin
 */

.owl-carousel {
  .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000;
  }

  .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    background: url("#{$owl-image-path}owl.video.play.png") no-repeat;
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    transition: transform 100ms ease;
  }

  .owl-video-play-icon:hover {
    transform: scale(1.3, 1.3);
  }

  .owl-video-playing .owl-video-tn,
  .owl-video-playing .owl-video-play-icon {
    display: none;
  }

  .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: opacity 400ms ease;
  }

  .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
  }
}

.owl-theme {
  // Styling Next and Prev buttons
  .owl-nav {
    margin-top: 10px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;

    [class*="owl-"] {
      color: $owl-nav-color;
      font-size: $owl-nav-font-size;
      margin: $owl-nav-margin;
      padding: $owl-nav-padding;
      background: $owl-nav-background;
      display: inline-block;
      cursor: pointer;
      border-radius: 3px;

      &:hover {
        background: $owl-nav-background-hover;
        color: $owl-nav-color-hover;
        text-decoration: none;
      }
    }
    .disabled {
      opacity: $owl-nav-disabled-opacity;
      cursor: default;
    }
  }

  // Styling dots
  .owl-nav.disabled + .owl-dots {
    margin-top: 10px;
  }

  .owl-dots {
    text-align: center;
    -webkit-tap-highlight-color: transparent;

    .owl-dot {
      display: inline-block;
      zoom: 1;
      *display: inline;

      span {
        width: $owl-dot-width;
        height: $owl-dot-height;
        margin: $owl-dot-margin;
        background: $owl-dot-background;
        display: block;
        -webkit-backface-visibility: visible;
        transition: opacity 200ms ease;
        border-radius: $owl-dot-rounded;
      }

      &.active,
      &:hover {
        span {
          background: $owl-dot-background-active;
        }
      }
    }
  }
}

// Keypath-added Styles
.owl-nav {
  & > .owl-prev {
    left: 0;
  }

  & > .owl-next {
    right: 0;
  }

  & > button {
    position: absolute;
    top: 35%;
    background: transparent !important;
    box-shadow: none !important;
    color: #fff !important;
    margin: 0 !important;
    line-height: 100%;
    z-index: 5;

    &:hover {
      color: $exeter-blue !important;
    }
    span {
      text-shadow: 1px 2px 2px #000;
      font-size: 70px;
      line-height: inherit;
      color: $exeter-bus-grey;

      @media (max-width: 615px) {
        font-size: 50px;
      }
    }
  }
}
.owl-dots {
  display: flex;
  justify-content: center;
  margin: 6px 26px;
  background: #eaeaea;
  padding: 6px;

  button.owl-dot {
    background: #7b858b !important;
    border-radius: 50%;
    height: 15px;
    margin: 0 10px !important;
    width: 15px;
    &.active,
    &:hover {
      background: #5f99ca !important;
      span {
        background: $exeter-bus-grey !important;
        border-radius: 50%;
        height: 10px;
        width: 10px;
      }
    }
  }
}

.owl-carousel.no-controls {
  overflow-y: hidden;

  .owl-dots,
  .owl-nav {
    display: none !important;
  }
}
