.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.flipOutX,
.animated.flipOutY,
.animated.bounceIn,
.animated.bounceOut {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
}

@-webkit-keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

@keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}

@-webkit-keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

@keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}

&.pagelayout-incourse.course-7108 #page section#region-main div[role="main"] {
  div.no-overflow {
    overflow: auto;
    overflow-x: visible; // overflow-y: scroll;
  }
}

main.kp-parallax {
  position: relative;
  ::-webkit-scrollbar {
    display: none;
  }
  & article {
    align-items: center;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    position: relative;
    height: 100%; // &.fixed-section {
    //   @media (max-width: 414px) {
    //     height: 100vh !important;
    //   }
  }
  blockquote {
    background-color: rgba(0, 0, 0, 0);
    font-size: 1.6rem;
    margin: 2rem 0 0;
    padding: 1rem 1rem 1rem 3rem;
    &:before {
      font-weight: normal;
    }
    &.cite {
      color: #af1926;
    }
  }
  strong {
    font-weight: bold;
  }
  h4 {
    color: #16243d;
  }
  .article-footer {
    background: rgba(22, 36, 61, 0.9);
    display: flex;
    justify-content: center;
    padding: 1.5rem;
    color: white;
    font-size: 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    align-items: center;
    @media (min-device-width: 220px) and (max-device-width: 414px) {
      display: none;
    }
    ul {
      text-align: center;
      list-style: none;
      margin-bottom: 0;
      @media (max-width: 900px) {
        margin: 0;
      }
      li {
        font-size: 1.4rem;
        display: inline;
        margin: 0 1.2rem;
        @media (min-width: 597px) and (max-width: 900px) {
          margin: 0 0.25rem;
          font-size: 1.184rem;
        }
        @media (max-width: 596px) {
          margin: 0 0.45rem;
          font-size: 1.114rem;
        }
        &:before {
          display: none;
        }
        &:hover {
          color: white;
          text-decoration: none;
        }
      }
      a {
        color: white;
        text-decoration: none;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  .sticky {
    align-items: center;
    background: #af1926;
    border-radius: 5px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    position: absolute;
    top: 0;
    transition: all 350ms ease-in-out;
    width: 100%;
    &.stuck {
      background: #16243d;
      left: 0;
      position: fixed;
      top: 0;
      transition: background 350ms ease-in-out;
      width: 100%;
      & a,
      & span {
        color: #fff;
        transition: background 350ms ease-in-out;
      }
    }
    & * {
      font-size: 2.5rem;
      text-decoration: none;
    }
  }
  .article-content {
    opacity: 1;
    transition: all 400ms ease-in-out;
    line-height: 1.5;
    & .fa {
      font-size: 3.5rem;
      margin-right: 10px;
    }
    &.is-hidden {
      opacity: 0;
      transition: all 400ms ease-in-out;
    }
    h1 {
      font-size: 2rem;
    }
  }
  .content-frame {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0 auto;
    position: relative;
    @media (min-width: 901px) and (max-width: 1200px) {
      width: 95%;
    }
    @media (max-width: 900px) {
      flex-direction: column;
      max-width: none;
      width: 95%;
    }
    .fa {
      color: #16243d;
    }
    .content-block {
      background: rgba(255, 255, 255, 0.95);
      border-radius: 5px;
      color: #16243d;
      margin: 2rem 3rem;
      width: 90%;
      padding: 3rem 5rem;
      @media (max-width: 900px) {
        width: 90%;
        padding: 7%;
        margin: 1rem auto;
      }
      h1.xl-title {
        font-size: 4rem;
        text-align: center;
        font-family: inherit;
        margin-bottom: 0;
        @media (min-device-width: 220px) and (max-device-width: 414px) {
          font-size: 1.72rem;
        }
        @media (max-width: 414px) {
          font-size: 1.72rem;
        }
        @media (min-width: 415px) and (max-width: 596px) {
          font-size: 2.52rem;
        }
        @media (min-width: 597px) and (max-width: 900px) {
          font-size: 2.72rem;
        }
      }
      h5.xl-content {
        // font-size: 1.662rem;
        color: #16243d;
        line-height: 1.662;
        @media (min-device-width: 220px) and (max-device-width: 414px) {
          font-size: 1.16rem;
        }
        @media (max-width: 414px) {
          font-size: 1.16rem;
        }
        @media (min-width: 415px) and (max-width: 900px) {
          font-size: 1.22rem;
        }
      }
      h2.xl-content {
        font-size: 3rem;
        margin-top: 2rem;
        text-align: center;
      }
      hr {
        background: #0078ff;
      }
      & h3 {
        text-align: center;
        background: none;
        padding: 0;
        color: #4b4e51;
        width: 100%;
        position: static;
        display: block;
      }
      ul a {
        text-decoration: none;
        color: #16243d;
        &:hover {
          text-decoration: none;
        }
      }
      h3 span {
        display: block;
        font-size: 2rem;
      }
      ul {
        font-size: 1.662rem;
        list-style-type: none;
        @media (min-device-width: 220px) and (max-device-width: 414px) {
          font-size: 1.16rem;
        }
        @media (max-width: 414px) {
          font-size: 1.16rem;
        }
        @media (min-width: 415px) and (max-width: 900px) {
          font-size: 1.2rem;
        }
        li {
          margin-bottom: 2.5rem;
          line-height: 1.8;
          &:before {
            display: none;
          }
        }
      }
      ul.bulleted {
        font-size: 1.8rem;
        list-style-type: disc;
      }
      img {
        max-width: 100%;
      }
      h1:last-of-type,
      p:last-of-type {
        margin-bottom: 0;
      }
      p {
        font-size: 1.8rem;
      }
    }
  }
}

.s-d {
  bottom: 10%;
  position: absolute;
  @media (min-device-width: 220px) and (max-device-width: 414px) {
    display: none;
  }
  .icon {
    text-align: center;
    .fa {
      animation: bounce 1s linear infinite;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
      top: 70%;
      @media (min-width: 597px) and (max-width: 900px) {
        top: 170%;
      }
      @media (max-width: 596px) {
        top: 200%;
      }
    }
  }
}

.icon-row {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
  text-align: center;
  .icon {
    cursor: pointer;
  }
  svg {
    margin-top: 1.5rem;
    max-width: 75px;
  }
  svg g,
  .icon-row .icon svg path {
    fill: #16243d;
    transition: fill 300ms ease-in-out;
  }
  &:hover svg g,
  .icon-row .icon:hover svg path {
    fill: #af1926;
    transition: fill 300ms ease-in-out;
  }
}

.kp-accordion,
.accordion-header,
.accordion-content,
.acc-item {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  margin: 0;
  padding: 0;
  width: 100%;
}

.kp-accordion {
  display: none;
}

.kp-accordion.active {
  display: block;
  margin-top: 2rem;
}

.accordion-header {
  background: #16243d;
  color: #fff;
  font-size: 1.8rem;
  padding: 1rem;
  text-align: center;
}

.accordion-content {
  border-bottom: 1px solid #ddd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  padding: 0;
  -webkit-transition: 350ms all ease-in-out;
  transition: 350ms all ease-in-out;
}

.accordion-content.open {
  max-height: 1000px;
  -webkit-transition: 350ms all ease-in-out;
  transition: 350ms all ease-in-out;
}

.accordion-content {
  .acc-item {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-right: 1px solid #ddd;
    color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 2.2rem;
    letter-spacing: 1px;
    list-style-position: inside;
    list-style-type: disc;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: 150px;
    text-align: center;
    text-shadow: 0px 2px 0 black;
    width: 33.33%;
  }
}

.accordion-content .acc-item:nth-of-type(3n + 3) {
  border-right: none;
}

.accordion-content .acc-item:nth-of-type(-n + 3) {
  border-bottom: 1px solid #ddd;
}

.pad-bot {
  margin-bottom: 1rem;
}

#progress-frame {
  background: #16243d;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
}

#progress-frame #progressbar {
  background: #af1926;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 0;
}

&.noscroll {
  width: 100vw;
  position: fixed !important;
  z-index: 0;
}

// :root {
//   font-size: 12px;
// }
