.custom-reflective-learning {
  box-sizing: border-box;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  max-width: 1000px;
  margin: auto;
  overflow: hidden;
}

.custom-reflective-learning button {
  text-align: center !important;
  font-size: 14px !important;
  background-color: #004c9c;
  margin-top: 1em;
  margin-bottom: 0.5em;
  padding: 0 15px;
  border-radius: 4px;
  color: white !important;
  font-weight: bold !important;
  border: none;
  float: right;
  margin-right: 15px;
  cursor: pointer;
}

.custom-reflective-learning button:hover {
  background-color: #003369;
}

.custom-reflective-learning button:disabled {
  border: none;
}

.custom-reflective-learning th {
  background-color: #004c9c;
  color: white;
}

.custom-reflective-learning .row-head th {
  height: 60px;
}

.custom-reflective-learning tr textarea {
  width: 100%;
  padding: 5px;
}
.custom-reflective-learning tr textarea:read-only {
  background-color: #f5f5f5;
}

.custom-reflective-learning #custom-reflective-learning-save {
  margin-right: 15px;
  width: 140px;
  font-size: 16px !important;
}

.custom-reflective-learning #custom-reflective-learning-pdf {
  width: 140px;
  font-size: 16px !important;
  background-color: #666 !important;
}
