#page-wrapper {
  #page {
    #page-content {
      div[role="main"] {
        .test-area {
          .test-heading {
            font-family: "Lato", sans-serif;
            font-weight: 700;
          }

          .test-text {
            font-family: "Lato", sans-serif;
            font-size: 1.1rem;
            font-weight: 400;
            font-style: normal;
          }
        }
      }
    }
  }
}
