.no-space {
  margin-top: 0;
}

.single-space {
  margin-top: 1rem;
}

.double-space {
  margin-top: 2rem;
}

.triple-space {
  margin-top: 3rem;
}

.single-space-left {
  margin-left: 2rem;
}

.double-space-left {
  margin-left: 4rem;
}

.single-space-right {
  margin-right: 2rem;
}

.double-space-right {
  margin-right: 4rem;
}

.single-pinch {
  padding-left: 2rem;
  padding-right: 2rem;
}

.double-pinch {
  padding-left: 4rem;
  padding-right: 4rem;
}
