.wordcloudnew-outermost-container {
  #wordcloudnew-reveal-wordcloud {
    display: none;
  }
  #wordcloudnew-main-input {
    width: 100%;
    resize: none;
    height: 200px;
    padding: 20px;
  }
  .wordcloudnew-input-none {
    color: #808080;
  }
  .wordcloudnew-main-input-btn-container {
    margin-top: 5px;
  }
  .wordcloudnew-main-input-reveal-container {
    text-align: right;
  }
  #wordcloudnew-main-word-cloud {
    margin-top: 10px;
  }
  .wordcloudnew-responses-header {
    display: none;
    strong {
      border-bottom: 2px solid #004c9c;
      color: black;
      padding: 5px 10px;
    }
  }
  .wordcloudnew-responses-outer-container {
    display: none;
    max-height: 300px;
    overflow-y: scroll;
    margin-top: 10px;

    .wordcloudnew-responses-container {
      margin-top: 10px;
      p {
        padding: 5px;
        margin: 1px;
      }
      p:nth-child(odd) {
        background: #fff;
        color: #333333;
      }
      p:nth-child(even) {
        background: #eaeaea;
        color: #333333;
      }
      p:hover {
        background: #666;
        color: #fff;
      }
      p:before {
        content: "";
        height: 100%;
        width: 10px;
        line-height: 0;
        border-radius: 0;
        top: 1.3em;
        background: rgb(248, 248, 176);
        border: none;
        left: -1em;
      }
    }
  }
}
