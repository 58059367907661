@import url("https://fonts.googleapis.com/css?family=Lato:400,700");
// ENTER YOUR STYLES HERE
a.btn-for-flip {
  background: #004c9c;
  border-radius: 4px;
  box-shadow: 0 2px 0px 0 rgba(0, 0, 0, 0.25);
  color: #ffffff;
  display: inline-block;
  padding: 6px 30px 8px;
  position: relative;
  text-decoration: none;
  -webkit-transition: all 0.1s 0s ease-out;
  transition: all 0.1s 0s ease-out;
}

.no-touch a.btn-for-flip {
  &:hover {
    background: #00a2ad;
    box-shadow: 0px 8px 2px 0 rgba(0, 0, 0, 0.075);
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
    -webkit-transition: all 0.25s 0s ease-out;
    transition: all 0.25s 0s ease-out;
  }
  &:active {
    background: #008a93;
    box-shadow: 0 1px 0px 0 rgba(255, 255, 255, 0.25);
    -webkit-transform: translate3d(0, 1px, 0);
    transform: translate3d(0, 1px, 0);
    -webkit-transition: all 0.025s 0s ease-out;
    transition: all 0.025s 0s ease-out;
  }
}

a.btn-for-flip:active {
  background: #008a93;
  box-shadow: 0 1px 0px 0 rgba(255, 255, 255, 0.25);
  -webkit-transform: translate3d(0, 1px, 0);
  transform: translate3d(0, 1px, 0);
  -webkit-transition: all 0.025s 0s ease-out;
  transition: all 0.025s 0s ease-out;
}
a.btn-primary {
  color: #fff !important;
}
a.btn-secondary {
  color: #373a3c !important;
}
.cards-for-flip {
  margin: 80px auto;
  max-width: 960px;
  text-align: center;
}

.card-for-flip {
  background: #ffffff;
  box-shadow: 0 0 10px 0 rgba(50, 50, 50, 0.25);
  display: inline-block;
  margin: 8px;
  max-width: 260px;
  -webkit-perspective: 1000;
  perspective: 1000;
  position: relative;
  text-align: left;
  -webkit-transition: all 0.3s 0s ease-in;
  transition: all 0.3s 0s ease-in;
  z-index: 1;
  img {
    max-width: 100% !important;
  }
  .card-title {
    background: #ffffff;
    margin-bottom: 0;
    padding: 6px 15px 10px;
    position: relative;
    z-index: 0;
    a.toggle-info {
      border-radius: 32px;
      height: 32px;
      padding: 0;
      position: absolute;
      right: 15px;
      top: 10px;
      width: 32px;
      span {
        background: #ffffff;
        display: block;
        height: 2px;
        position: absolute;
        top: 16px;
        -webkit-transition: all 0.15s 0s ease-out;
        transition: all 0.15s 0s ease-out;
        width: 12px;
        &.left {
          right: 14px;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
        }
        &.right {
          left: 14px;
          -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
      }
    }
    h4 {
      background: white;
      color: #4b4e51;
      font-size: 24px;
      font-weight: 700;
      letter-spacing: -0.05em;
      margin: 0 !important;
      padding: 0 2rem 0 0;
      &:after,
      &:before {
        content: none !important;
      }
    }
    small {
      display: block;
      font-family: Georgia, serif;
      font-size: 19px;
    }
  }
  .card-description {
    font-family: Arial;
    padding: 0 15px 10px;
    position: relative;
    font-size: 14px;
  }
  .card-actions {
    box-shadow: 0 2px 0px 0 rgba(0, 0, 0, 0.075);
    padding: 10px 15px 20px;
    text-align: center;
  }
  .card-flap {
    background: #d9d9d9;
    position: absolute;
    width: 100%;
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-transform: rotateX(-90deg);
    transform: rotateX(-90deg);
  }
  .flap1 {
    -webkit-transition: all 0.3s 0.3s ease-out;
    transition: all 0.3s 0.3s ease-out;
    z-index: -1;
  }
  .flap2 {
    -webkit-transition: all 0.3s 0s ease-out;
    transition: all 0.3s 0s ease-out;
    z-index: -2;
  }
}

.cards-for-flip.showing .card-for-flip {
  cursor: pointer;
  opacity: 0.6;
  -webkit-transform: scale(0.88);
  transform: scale(0.88);
}

.no-touch .cards-for-flip.showing .card-for-flip:hover {
  opacity: 0.94;
  -webkit-transform: scale(0.92);
  transform: scale(0.92);
}

.card-for-flip.show {
  opacity: 1 !important;
  -webkit-transform: scale(1) !important;
  transform: scale(1) !important;
  .card-title a.toggle-info {
    background: #a80000 !important;
    span {
      top: 15px;
      &.left {
        right: 10px;
      }
      &.right {
        left: 10px;
      }
    }
  }
  .card-flap {
    background: #ffffff;
    box-shadow: 0 0 10px 0 rgba(50, 50, 50, 0.25);
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
  }
  .flap1 {
    -webkit-transition: all 0.3s 0s ease-out;
    transition: all 0.3s 0s ease-out;
  }
  .flap2 {
    -webkit-transition: all 0.3s 0.2s ease-out;
    transition: all 0.3s 0.2s ease-out;
  }
}
.options.clearfix {
  width: 100%;
}
